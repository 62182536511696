import React  from 'react';
import "./Button.scss";


interface OwnProps {
    color?: 'brown';
    children?: React.ReactNode;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    onClick?: () => void;
    sm?:boolean;
}
const Button: React.FC<OwnProps> = ({children, type = "button", sm = false, loading = false,
                    disabled=false, className = '', onClick = undefined}) => {

    return (<button className={`btn-custom ${className} ${sm ? "sm": ""}`} type= {type} disabled={disabled || loading} onClick={onClick}>
        {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> } {children}
    </button>)
}

export default Button
