import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikHelpers } from 'formik';
import Button from 'components/Button';
import Header from 'components/Header';
import './Inscription.scss';
import PopIn from 'components/PopIn';
import { ROUTES } from 'resources/routes-constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IUser from 'models/IUser';
import { getFirebase } from 'firebase_helper';
import ISignUpResult from 'models/ISignUpResult';
import { useAppDispatch } from 'store/store';
import { setResetQuestions, setSignUpResult } from 'store/reducers/espressoJeuReducer';
import IGameResult from 'models/IGameResult';
import gameDates from 'resources/gameDates';



const Inscription: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const [loading, setLoading] = useState(false);

    const [showPopInPrice200Cap, setShowPopInPrice200Cap] = useState(false);
    const [showPopInPrice50Cap, setShowPopInPrice50Cap] = useState(false);
    const [showPopInPrice5Euros, setShowPopInPrice5Euros] = useState(false);

    const [searchParams] = useSearchParams();
    const param_user_id = searchParams.get("user_id");
    const param_firstname = searchParams.get("firstname");
    const param_lastname = searchParams.get("lastname");
    const param_email = searchParams.get("email");
    const param_tel = searchParams.get("tel");
    

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.setProperty("overflow", "auto");
        return () => {
            document.getElementsByTagName("body")[0].style.setProperty("overflow", "hidden");
        }
    }, [])


    const onSubmit = async (values: IUser, formikHelpers: FormikHelpers<IUser>)=> {

        const fireBaseBackend = getFirebase();

        setLoading(true);
        const result = await fireBaseBackend.signup({...values, external_user_id: param_user_id})
        .finally(() => {
            setLoading(false);
        })

        const resultSignup = result.data as ISignUpResult;

        dispatch(setSignUpResult(resultSignup));
        dispatch(setResetQuestions())
        if(resultSignup.alreadyPlayed)
        {
            navigate(ROUTES.JEU_GAME_ALREADY_PLAYED_ROUTE)
        }
        else
        {
            navigate(ROUTES.JEU_QUESTION1_ROUTE)
        }
        
        return;
    }


    const schema = yup.object().shape({
        email: yup.string().required("Votre mail est obligatoire"),
        firstName: yup.string().required("Votre prénom est obligatoire"),
        lastName: yup.string().required("Votre nom de famille est obligatoire"),
        telephone: yup.string().required("Votre téléphone est obligatoire"),
        newsletterInscription: yup.bool().optional(),
        cgvAccepted: yup.bool().isTrue("Vous devez lire et accepter le réglement du jeu"),
        isAdult: yup.bool().isTrue("Vous devez être majeur pour participer à ce jeu concour")
      });

      
    return (<>
        <div id="inscription-page">


            <div id="panel-header">
                <Header />
                <h2 id="title" className='mb-2 text-center text-white fs-5'><b>{"Jouez pour découvrir le café qui vous ressemble, parmi les 60 cafés de haute qualité Nespresso"}</b></h2>
            </div>

            <div className='mb-2 d-none d-md-block text-center fs-5'><b>{"Vous n'êtes pas encore membre du Club Nespresso ?"}</b> Ce jeu est fait pour vous en <b>{"exclusivité jusqu'au " + gameDates.dateToLong}</b></div>
            <div id="panel">
                <Container>
                    <Row className=''>
                        <Col className='presentation text-center'>
                            <div className='d-none d-md-block mb-3 fs-5'>{"Nespresso agit pour que chaque tasse de café soit le fruit d'une démarche responsable et plus durable"}</div>
                            <div id="panel-header-desktop" className='fs-4'><b>{"Jouez pour découvrir le café qui vous ressemble, parmi les 60 cafés de haute qualité Nespresso"}</b></div>
                            <div className='mt-3 fs-4'>{"Et tentez"} <b>de gagner</b></div>


                            <Row id="description-lot" className='text-center align-items-center '>
                                <Col className='prices lot1'>
                                    <div className='fs-6 mb-2'>un lot de</div>
                                    <div className='fs-1 font-bold'>200  <span className='fs-5 text-uppercase d-inline d-sm-inline d-md-none'>capsules</span></div>
                                    <div className='fs-3 text-uppercase d-none d-md-block font-bold'>capsules</div>
                                    <div className='fs-4 font-bold' >de café Nespresso</div>
                                    <div className='hover-section' onClick={() => setShowPopInPrice200Cap(true)}><FontAwesomeIcon icon={["fas", "arrow-right"]} size="lg"/></div>
                                    <PopIn show={showPopInPrice200Cap} onHide={() => setShowPopInPrice200Cap(false)}> 
                                        <h2 className='text-uppercase text-center'>200 capsules</h2>
                                        <div className='text-center mb-3 fs-14px'><b>{"1 gagnant pourra remporter 200 capsules de café Nespresso"}</b></div>
                                        <div className='text-center mb-3 fs-14px'><b>{"Un assortiment prédéfini de 20 étuis de café au choix issus de la gamme Original ou Vertuo"}</b></div>
                                        <div className='text-center fs-14px'>{"D’une valeur de 92€ pour la gamme Original ou 110,40€ pour la gamme Vertuo"}</div>
                                    </PopIn>
                                </Col>


                                <Col className='or d-block d-sm-block d-md-none' style={{flexBasis: "100%"}}><b>ou</b></Col>
                                <Col className='or d-none d-md-block'><b>ou</b></Col>

                                <Col className='prices lot2'>
                                    <div className='fs-6 mb-2'>un des 3 lots de</div>
                                    <div className='fs-1 font-bold'>50  <span className='fs-5 text-uppercase d-inline d-sm-inline d-md-none'>capsules</span></div>
                                    <div className='fs-3 text-uppercase d-none d-md-block font-bold'>capsules</div>
                                    <div className='fs-4 font-bold'>de café Nespresso</div>
                                    <div className='hover-section' onClick={() => setShowPopInPrice50Cap(true)}><FontAwesomeIcon icon={["fas", "arrow-right"]} size="lg"/></div>
                                    <PopIn show={showPopInPrice50Cap} onHide={() => setShowPopInPrice50Cap(false)}> 
                                        <h2 className='text-uppercase text-center'>50 capsules</h2>
                                        <div className='text-center mb-3 fs-14px'><b>{"3 gagnants pourront remporter 50 capsules de café Nespresso"}</b></div>
                                        <div className='text-center mb-3 fs-14px'><b>{"Un assortiment prédéfini de 5 étuis de café au choix issus de la gamme Original ou Vertuo"}</b></div>
                                        <div className='text-center fs-14px'>{"D’une valeur de 22,90€ pour la gamme Original ou 26,60€ pour la gamme Vertuo"}</div>
                                    </PopIn>
                                </Col>

                                <Col className='or d-block d-sm-block d-xl-none' style={{flexBasis: "100%"}}><b>ou</b></Col>
                                <Col className='or d-none d-xl-block'><b>ou</b></Col>

                                <Col className='prices lot3'>
                                    <div className='fs-6 mb-2'>Une des 1000</div>

                                    {/*<div className='fs-5 text-uppercase'>Remises</div>
                                    <div>de <b className='fs-1'>5€</b></div>*/}

                                    <div className='fs-6 text-uppercase d-inline d-sm-inline d-md-none font-bold'><span className=''>Remises de <span className="fs-1">5€</span></span></div>
                                    <div className='fs-3 text-uppercase d-none d-md-block font-bold'> <div className='fs-2 text-uppercase'>remises</div>de <b className='fs-1'>5€</b></div>
                                    

                                    <div className='hover-section' onClick={() => setShowPopInPrice5Euros(true)}><FontAwesomeIcon icon={["fas", "arrow-right"]} size="lg"/></div>
                                    <PopIn show={showPopInPrice5Euros} onHide={() => setShowPopInPrice5Euros(false)}> 
                                        <h2 className='text-uppercase text-center'>Remises de 5€</h2>
                                        <div className='text-center mb-3 fs-14px'><b>{"En cas de gain, vous recevrez par email un code privilège exclusif qui vous permettra de bénéficier de la remise de 5€."}</b></div>
                                        <div className='text-center mb-3 fs-14px'><b>{"Code valable des 50 capsules commandées et réservé aux nouveaux clients exclusivement."}</b></div>
                                    </PopIn>
                                </Col>
                            </Row>
                        </Col>
                           
                        <Col md={5} className='form'>
                            <div className='mb-3 text-center fs-14px'>{"Pour participer et découvrir votre café préféré, complétez le formulaire :"}</div>
                            <Formik
                                validationSchema={schema}
                                onSubmit={onSubmit}
                                initialValues={{
                                    email: param_email,
                                    firstName: param_firstname,
                                    lastName: param_lastname,
                                    telephone:param_tel,
                                    newsletterInscription: false,
                                    cgvAccepted: false,
                                    isAdult: false
                                } as IUser}>
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    values,
                                    touched,
                                    isValid,
                                    isSubmitting,
                                    errors,
                                }) => {
                                    return (
                                        <fieldset disabled={false/*isSubmitting*/}>
                                             <Form.Group className='mb-3' controlId="validationFormik02">
                                                    <Form.Control
                                                        type="text"
                                                        name="lastName"
                                                        placeholder='Nom*'
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        isValid={touched.lastName && !errors.lastName}
                                                        isInvalid={touched.lastName && !!errors.lastName}
                                                    />
                                                    {/*<Form.Control.Feedback type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>
                                                <Form.Group className='mb-3' controlId="validationFormik01">
                                                    <Form.Control
                                                        type="text"
                                                        name="firstName"
                                                        placeholder='Prénom*'
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        isValid={touched.firstName && !errors.firstName}
                                                        isInvalid={touched.firstName && !!errors.firstName}
                                                    />
                                                    {/*<Form.Control.Feedback type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>*/}
                                                </Form.Group>
                                                <Form.Group className='mb-3' controlId="validationFormik03">
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        placeholder='Email*'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        isValid={touched.email && !errors.email}
                                                        isInvalid={touched.email && !!errors.email}
                                                    />
                                                    {/*<Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>

                                                <Form.Group className='mb-3' controlId="validationFormik04">
                                                    <Form.Control
                                                        type="text"
                                                        name="telephone"
                                                        placeholder='Téléphone*'
                                                        value={values.telephone}
                                                        onChange={handleChange}
                                                        isValid={touched.telephone && !errors.telephone}
                                                        isInvalid={touched.telephone && !!errors.telephone}
                                                    />
                                                    {/*<Form.Control.Feedback type="invalid">
                                                        {errors.telephone}
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>

                                                <Form.Group className='mb-3' controlId="validationFormik05">
                                                    <Form.Check 
                                                        type="checkbox"
                                                        name="newsletterInscription"
                                                        label={<small className='fs-11px'>{"Je souhaite recevoir des actualités et des offres promotionnelles de la part de Nespresso par mail"}</small>}
                                                        onChange={(e) => { setFieldValue("newsletterInscription", e.target.checked)}}
                                                        value={"newsletterInscription"}
                                                    />
                                                </Form.Group>

                                                <Form.Group className='mb-3' controlId="validationFormik06">
                                                    <Form.Check 
                                                        type="checkbox"
                                                        name="cgvAccepted"
                                                        label={<small className={'fs-11px ' + (errors.cgvAccepted ? "text-danger":"")}>{"J'ai lu et j'accepte le réglement du jeu ainsi que le traitement de mes données personnelles dans le cadre de la gestion du jeu.*"}</small>}
                                                        onChange={(e) => { setFieldValue("cgvAccepted", e.target.checked)}}
                                                        value={"cgvAccepted"}
                                                        isValid={touched.cgvAccepted && !errors.cgvAccepted}
                                                        isInvalid={touched.cgvAccepted && !!errors.cgvAccepted}
                                                    />
                                                    {/*<Form.Control.Feedback type="invalid">
                                                        {errors.cgvAccepted}
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>

                                                <Form.Group className='mb-3' controlId="validationFormik077">
                                                    <Form.Check 
                                                        type="checkbox"
                                                        name="isAdult"
                                                        label={<small className={'fs-11px ' + (errors.isAdult ? "text-danger":"")}>{"Je certifie être majeur, j'ai lu la"} <a href='https://www.nespresso.com/fr/fr/politique-protection-des-donnees-personnelles' target='blank'>politique de Nespresso sur la protection des données personnelles</a>{", et j'accepte l'utilisation de mes données personnelles faites dans ce cadre"}</small>}
                                                        onChange={(e) => { setFieldValue("isAdult", e.target.checked)}}
                                                        value={"isAdult"}
                                                        isValid={touched.isAdult && !errors.isAdult}
                                                        isInvalid={touched.isAdult && !!errors.isAdult}
                                                    />
                                                    {/*<Form.Control.Feedback type="invalid">
                                                        {errors.isAdult}
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>

                                                <div className='d-flex justify-content-center'>
                                                    {/*<ButtonFno className="w-100 me-1" color='blue' onClick={() => navigate(ROUTES.ADMIN.CLIENTS)}>{t('Retour')}</ButtonFno>*/}
                                                    <Button loading={loading} onClick={() => handleSubmit()} className="w-100 fs-5">{'Je joue'}</Button>
                                                </div>
                                            </fieldset>
                                        )
                                }}
                                </Formik>
                        </Col>
                    </Row>
                </Container>
            </div>
            
        </div>
        <div id="footer-legal-information">
                <div className='text-center'>*Champs obligatoires</div>

                <div className='text-center fs-11px' style={{fontFamily: "inherit"}}>{`
                «Jeu sans obligation d'achat organisé par la société Nespresso France, ouvert du ${gameDates.dateFromShort} à 6h00 au ${gameDates.dateToShort} à 23h59 inclus, réservé exclusivement aux nouveaux clients Nespresso (personne physique majeure, non enregistrée sur le site www.nespresso.com ou en boutique Nespresso et ne disposant pas d’un numéro personnel du Club Nespresso), résidant en France (Corse et Outre-Mer inclus) et limité à 1 participation par personne. Pour participer, se rendre sur le site www.jeu-cafe.nespresso.com et suivre les étapes de participation indiquées. Mise en jeu de 1 lot de 20 étuis de cafés prédéterminés par Nespresso au choix entre les gammes Original (valeur estimative : 90,50 € TTC) ou Vertuo (valeur estimative : 107,80 € TTC), de 3 lots de 5 étuis de cafés prédéterminés par Nespresso au choix entre les gammes Original (valeur estimative : 23,30 € TTC) ou Vertuo (valeur estimative : 26 € TTC) et de 1000 lots de 1 code promo contenant une remise immédiate de 5€ TTC, utilisable ensuite dès l’achat de 50 capsules Vertuo ou Original (voir conditions d’utilisation du code dans l’email de confirmation du lot et dans le règlement). Voir modalités complètes sur www.jeu-cafe.nespresso.com . Le règlement peut également être adressé sur simple demande formulée à Nespresso France SAS 27 – 33 rue du Colonel Pierre Avia - 75015 PARIS avant le 15/10/2023»`}
                    </div>
            </div>
    </>
    )
}

export default Inscription