import { initializeApp } from "firebase/app";
import { Functions, connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import IUser from "models/IUser";


class FirebaseBackend {


  //firestore: firebase.firestore.Firestore = {} as firebase.firestore.Firestore;
  functions: Functions = {} as Functions;
  //storage: firebase.storage.Storage = {} as firebase.storage.Storage;
  //auth: firebase.auth.Auth = {} as firebase.auth.Auth;

  constructor(useEmulator = false) {
      try{
          const firebaseConfig: any = {		
            apiKey: process.env.REACT_APP_APIKEY,
            authDomain: process.env.REACT_APP_AUTHDOMAIN,
            databaseURL: process.env.REACT_APP_DATABASEURL,
            projectId: process.env.REACT_APP_PROJECTID,
            storageBucket: process.env.REACT_APP_STORAGEBUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
            appId: process.env.REACT_APP_APPID,
            measurementId: process.env.REACT_APP_MEASUREMENTID,
          }

          // Initialize Firebase
          const app = initializeApp(firebaseConfig);

          //this.auth = firebase.auth();
          this.functions = getFunctions(app, 'europe-west1');
          //this.firestore = firebase.firestore();
          //this.storage = firebase.storage();
          
          // allow us to set "undefined" for fields in database
          //this.firestore.settings({ignoreUndefinedProperties: true});


          if(useEmulator)
          {
            console.warn("!!! USING EMULATOR LOCALHOST URL !!!")
            connectFunctionsEmulator(this.functions, "localhost", 5001);
          }

      }
      catch(err)
      {
        console.error("Init firebase error", err)
      }
  }


  signup = (user: IUser) => {
      const signupFunc = httpsCallable(this.functions, "signup");
    return signupFunc({user: user});
  }
  populate = () => {
      const populateFunc = httpsCallable(this.functions, "populateRemiseCodeCollection");
    return populateFunc();
  }
  checkResult = (email: string, rep1: string, rep2: string, rep3: string) => {
      const checkGameResultFunc = httpsCallable(this.functions, "checkGameResult");
    return checkGameResultFunc({
      email,
      rep1,
      rep2,
      rep3
    });
  }

}


let _fireBaseBackend: FirebaseBackend;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (useEmulator = false) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseBackend(useEmulator);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebase = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebase };
