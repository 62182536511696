import React from 'react';
import "./Reglement.scss";
import gameDates from 'resources/gameDates';
const Reglement: React.FC = () => {
    return (
<>
  <div id="reglement" className="c13 doc-content">
  <p className="c3"><span className="c2">REGLEMENT DU JEU NESPRESSO</span></p>
      <p className="c3"><span className="c5 c2">&laquo; Grand Jeu VOTRE CAFE NESPRESSO PREFERE &raquo;</span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c1"><span className="c5 c2"></span></p>
      <p className="c4"><span className="c5 c2">Article 1 : ORGANISATEUR ET PARTICIPANTS </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c14">La soci&eacute;t&eacute; NESPRESSO FRANCE S.A.S. (ci-apr&egrave;s la &laquo; Soci&eacute;t&eacute; Organisatrice &raquo;) dont le si&egrave;ge social est situ&eacute; 27 &ndash; 33 rue du Colonel Pierre Avia &ndash; 75015 Paris et dont les coordonn&eacute;es t&eacute;l&eacute;phoniques sont 01.87.60.21.00, inscrite sous le num&eacute;ro RCS Paris 382 597 821, organise un jeu sur Internet gratuit et sans obligation d&#39;achat du {gameDates.dateFromLong} &agrave; 06h00 au {gameDates.dateToLong} &agrave; 23h59 inclus, intitul&eacute; &laquo; </span><span className="c2">Grand Jeu Votre caf&eacute; Nespresso pr&eacute;f&eacute;r&eacute; </span><span className="c14">&raquo; (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo; le Jeu &raquo;). Le Jeu sera accessible uniquement &agrave; partir du site Internet suivant : </span><span className="c7">https://jeu-cafe.nespresso.com/jeu </span><span className="c0">(ci-apr&egrave;s le &laquo; Site &raquo;). </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Ce Jeu est ouvert exclusivement aux nouveaux clients Nespresso, r&eacute;sidant en France (Corse et Outre-Mer inclus), &agrave; l&#39;exclusion des membres du personnel de la Soci&eacute;t&eacute; Organisatrice ainsi que de leur famille, y compris les concubins, et d&#39;une fa&ccedil;on g&eacute;n&eacute;rale des soci&eacute;t&eacute;s participant directement ou indirectement &agrave; la mise en oeuvre du Jeu. Un nouveau client Nespresso est une personne physique majeure, non enregistr&eacute;e sur le site Internet www.nespresso.com ou en boutique Nespresso et ne disposant pas d&rsquo;un num&eacute;ro personnel du Club Nespresso. </span></p>
      <p className="c4"><span className="c0">Le pr&eacute;sent r&egrave;glement d&eacute;finit les r&egrave;gles applicables pour ce Jeu. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">Article 2 : CONDITIONS ET MODALITES DE PARTICIPATION</span></p>
      <p className="c4"><span className="c2">&nbsp;</span></p>
      <p className="c4"><span className="c0">Pour prendre part au Jeu, le participant doit : </span></p>
      <ul className="c9 lst-kix_list_4-0 start">
         <li className="c4 c6 li-bullet-0"><span className="c0">Se connecter entre le {gameDates.dateFromLong} &agrave; 06h00 et le {gameDates.dateToLong} &agrave; 23h59 inclus sur le site </span><span className="c5 c7">https://jeu-cafe.nespresso.com/jeu </span></li>
         <li className="c6 c10 li-bullet-0"><span className="c0">&nbsp;Inscrire ses coordonn&eacute;es dans le formulaire de participation d&eacute;di&eacute; sur le site </span><span className="c5 c7">https://jeu-cafe.nespresso.com/jeu </span><span className="c0">(champs obligatoires &gt; Nom, Pr&eacute;nom, Email, Num&eacute;ro de t&eacute;l&eacute;phone) &nbsp;Prendre connaissance du pr&eacute;sent r&egrave;glement de jeu et cocher la case demandant l&rsquo;acceptation du pr&eacute;sent r&egrave;glement </span></li>
         <li className="c10 c6 li-bullet-0"><span className="c0">Cliquer sur le bouton &laquo; Je joue &raquo; </span></li>
         <li className="c4 c6 li-bullet-0"><span className="c0">R&eacute;pondre aux 3 questions et cliquer sur &laquo; Je valide &raquo; &agrave; chaque question </span></li>
      </ul>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Le participant acc&egrave;de ensuite &agrave; une page de r&eacute;sultat pour d&eacute;couvrir son profil caf&eacute; et des recommandations de capsules de caf&eacute; li&eacute;es &agrave; son profil puis doit cliquer sur le bouton &laquo; avez-vous gagn&eacute; &raquo;. En cliquant sur ce bouton, il va lancer le jeu de hasard (instant gagnant &ndash; interrogation de la base des &laquo; instants gagnants &raquo;). Le r&eacute;sultat est imm&eacute;diat : l&rsquo;&eacute;cran indique imm&eacute;diatement si le participant a ou non gagn&eacute;, et le cas &eacute;ch&eacute;ant son gain. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Pour &ecirc;tre valide, le formulaire de participation devra comporter l&rsquo;ensemble des informations requises des participants et notamment : leur nom, pr&eacute;nom, adresse e-mail et num&eacute;ro de t&eacute;l&eacute;phone. Tous les participants devront imp&eacute;rativement cocher la case &laquo; J&rsquo;ai lu et j&rsquo;accepte le r&egrave;glement &raquo;. La participation ne pourra pas &ecirc;tre prise en compte si cette derni&egrave;re case n&rsquo;est pas coch&eacute;e. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Si un participant participe au Jeu alors qu&rsquo;il est d&eacute;j&agrave; client Nespresso, sa participation sera annul&eacute;e apr&egrave;s v&eacute;rification par la Soci&eacute;t&eacute; Organisatrice. La v&eacute;rification s&rsquo;effectue notamment par le num&eacute;ro client et l&rsquo;adresse e-mail. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">D&rsquo;une fa&ccedil;on plus g&eacute;n&eacute;rale, toute d&eacute;claration incompl&egrave;te, mensong&egrave;re ou toute tentative de fraude de la part d&#39;un participant entra&icirc;nera la nullit&eacute; de sa participation. Aucune participation par courrier ou sous une forme autre que celle pr&eacute;vue au pr&eacute;sent r&egrave;glement ne sera prise en compte. </span></p>
      <p className="c4"><span className="c0">Une connexion &agrave; Internet ainsi qu&rsquo;une adresse &eacute;lectronique valide sont obligatoires pour participer au Jeu. </span></p>
      <p className="c4"><span className="c0">Chaque participant ne peut participer qu&#39;une (1) seule fois pendant toute la dur&eacute;e du Jeu et il ne peut y avoir qu&rsquo;une (1) seule participation par foyer (m&ecirc;me nom, m&ecirc;me adresse email). Le non-respect de cette disposition entrainera la disqualification du participant. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Chaque personne souhaitant jouer devra se conformer aux instructions qui lui seront donn&eacute;es sur le Site. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Un participant qui se serait cr&eacute;&eacute; plusieurs e-mails avec la m&ecirc;me adresse et le m&ecirc;me nom, sera disqualifi&eacute; et ne pourra pr&eacute;tendre au gain qui lui aurait &eacute;t&eacute; attribu&eacute;. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Les inscriptions (e-mail, nom etc.) contrefaites ou r&eacute;alis&eacute;es en contravention avec le pr&eacute;sent r&egrave;glement ou les textes l&eacute;gaux en vigueur entra&icirc;neront la disqualification du ou des participants concern&eacute;s. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Il n&#39;y aura qu&#39;un seul lot par foyer (m&ecirc;me nom et/ou m&ecirc;me adresse email et/ou m&ecirc;me adresse postale), le foyer &eacute;tant d&eacute;termin&eacute; par l&#39;ensemble des personnes vivant sous le m&ecirc;me toit. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit de proc&eacute;der &agrave; toute v&eacute;rification et de demander la production de tous justificatifs pour l&#39;application du pr&eacute;sent article et du pr&eacute;sent r&egrave;glement en g&eacute;n&eacute;ral. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">Article 3 : DESIGNATION DES GAGNANTS </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Pendant la dur&eacute;e de ce Jeu, 1 004 (mille quatre) instants gagnants seront programm&eacute;s permettant de d&eacute;signer les gagnants parmi les participants. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Les instants gagnants qui d&eacute;clenchent l&rsquo;attribution d&rsquo;un des lots &agrave; un participant sont pr&eacute;d&eacute;termin&eacute;s de fa&ccedil;on al&eacute;atoire. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Ces instants gagnants sont &laquo; ouverts &raquo;. Est ainsi d&eacute;clar&eacute; gagnant un participant qui joue au moment de l&rsquo;instant gagnant (date, heure, minute, seconde du serveur du Jeu) ou, si aucun participant ne joue &agrave; ce moment, le participant qui joue le premier apr&egrave;s cet instant gagnant. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Ainsi, une fois que le participant aura cliqu&eacute; sur le bouton pour d&eacute;couvrir son gain, s&rsquo;il joue au moment de l&rsquo;instant gagnant, il verra s&rsquo;afficher un &eacute;cran lui indiquant le lot remport&eacute;. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Si le participant joue au moment de l&rsquo;instant gagnant lui faisant remporter le lot de 1 (un) assortiment de 20 (vingt) &eacute;tuis de caf&eacute;s pr&eacute;d&eacute;termin&eacute;s par Nespresso d&eacute;taill&eacute; &agrave; l&rsquo;article 4 ci-apr&egrave;s ou un des 3 (trois) lots de 5 (cinq) &eacute;tuis de caf&eacute; pr&eacute;d&eacute;termin&eacute;s par Nespresso, le gagnant d&eacute;couvrira son lot sur l&rsquo;&eacute;cran et recevra ensuite un email de confirmation de son lot avec toutes les informations. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Le gagnant sera apr&egrave;s contact&eacute; par t&eacute;l&eacute;phone par le service client de la Soci&eacute;t&eacute; Organisatrice dans un d&eacute;lai de 4 semaines apr&egrave;s la date de fin du Jeu. Le gagnant devra communiquer son adresse postale au service client de la Soci&eacute;t&eacute; Organisatrice afin de pouvoir recevoir son lot. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Le lot sera adress&eacute; par transporteur au gagnant, &agrave; l&rsquo;adresse indiqu&eacute;e par lui lors de son &eacute;change t&eacute;l&eacute;phonique avec la Soci&eacute;t&eacute; Organisatrice dans un d&eacute;lai maximum de 8 (huit) semaines apr&egrave;s la r&eacute;ception par le gagnant de son email de confirmation du lot. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Il est pr&eacute;cis&eacute; que : </span></p>
      <ul className="c9 lst-kix_list_5-0 start">
         <li className="c4 c6 li-bullet-0"><span className="c0">Tout lot qui serait retourn&eacute; &agrave; la Soci&eacute;t&eacute; Organisatrice par le prestataire en charge du transport, pour quelque raison que ce soit (par exemple, le gagnant n&rsquo;habite plus &agrave; l&rsquo;adresse indiqu&eacute;e) sera consid&eacute;r&eacute; comme abandonn&eacute; par le gagnant. </span></li>
         <li className="c4 c6 li-bullet-0"><span className="c0">Tout lot non r&eacute;clam&eacute; dans un d&eacute;lai de 3 (trois) mois apr&egrave;s la r&eacute;ception de l&rsquo;email de confirmation du lot sera consid&eacute;r&eacute; comme abandonn&eacute; par le gagnant. </span></li>
      </ul>
      <p className="c4"><span className="c0">Si le participant joue au moment de l&rsquo;instant gagnant lui faisant remporter un des 1000 (mille) lots de 1 (un) code promo contenant une remise imm&eacute;diate de 5 euros TTC d&eacute;taill&eacute; &agrave; l&rsquo;article 4 ci-apr&egrave;s, le gagnant verra s&rsquo;afficher &agrave; l&rsquo;&eacute;cran ledit code promo qu&rsquo;il recevra &eacute;galement imm&eacute;diatement par email &agrave; l&rsquo;adresse communiqu&eacute;e dans le formulaire de participation au Jeu. </span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice ne saurait &ecirc;tre tenue responsable en cas d&rsquo;impossibilit&eacute; &agrave; joindre un ou plusieurs gagnants ou de mauvais acheminement du courrier &eacute;lectronique leur &eacute;tant destin&eacute;. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice d&eacute;cline toute responsabilit&eacute; en cas de perte, de d&eacute;gradation ou de vol du lot durant la livraison ou en cas de mauvais acheminement de l&rsquo;email. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Les participants qui seront d&eacute;sign&eacute;s gagnants, de par leur seule participation au Jeu, autorisent par avance la Soci&eacute;t&eacute; Organisatrice, &agrave; titre gracieux, &agrave; faire mention de leur nom, pr&eacute;nom et/ou commune de r&eacute;sidence &agrave; des fins publi-promotionnelles dans les messages presse et dans toutes les manifestations li&eacute;es au Jeu, sur tout support de communication, ce pendant une dur&eacute;e de 3 (trois) mois maximum. </span></p>
      <p className="c1"><span className="c5 c2"></span></p>
      <p className="c4"><span className="c2">Article 4 : PRESENTATION DES LOTS </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Sont mis en jeu : </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c12"><span className="c8">- </span><span className="c0">1 (un) lot de 20 (vingt) &eacute;tuis de caf&eacute; pr&eacute;d&eacute;termin&eacute;s par Nespresso (soit 200 capsules de caf&eacute;) &agrave; choisir parmi la gamme Vertuo ou Original comme suit : </span></p>
      <ul className="c9 lst-kix_list_7-0 start">
         <li className="c4 c6 li-bullet-0"><span className="c0">Soit l&rsquo;assortiment de 20 (vingt) &eacute;tuis de caf&eacute; de la gamme Vertuo, qui est pr&eacute;d&eacute;fini par Nespresso et non modifiable, d&rsquo;une valeur indicative de 110,40&euro; TTC (cent dix euros et quarante centimes toutes taxes comprises) ; </span></li>
         <li className="c6 c12 li-bullet-0"><span className="c0">Soit l&rsquo;assortiment de 20 (vingt) &eacute;tuis de caf&eacute; de la gamme Original, qui est pr&eacute;d&eacute;fini par Nespresso et non modifiable, d&rsquo;une valeur indicative de 92,00&euro; (quatre-vingt-douze euros toutes taxes comprises). </span></li>
      </ul>
      <p className="c12"><span className="c8">- </span><span className="c0">3 (trois) lots de 5 (cinq) &eacute;tuis de caf&eacute; pr&eacute;d&eacute;termin&eacute;s par Nespresso (soit 50 capsules de caf&eacute;) &agrave; choisir parmi la gamme Vertuo ou Original comme suit : </span></p>
      <ul className="c9 lst-kix_list_7-0">
         <li className="c4 c6 li-bullet-0"><span className="c0">Soit l&rsquo;assortiment de 5 (cinq) &eacute;tuis de caf&eacute; de la gamme Vertuo, qui est pr&eacute;d&eacute;fini par Nespresso et non modifiable, d&rsquo;une valeur indicative de 26,60&euro; TTC (vingt-six euros et soixante-centimes toutes taxes comprises); </span></li>
         <li className="c12 c6 li-bullet-0"><span className="c0">Soit l&rsquo;assortiment de 5 (cinq) &eacute;tuis de caf&eacute; de la gamme Original, qui est pr&eacute;d&eacute;fini par Nespresso et non modifiable, d&rsquo;une valeur indicative de 22,90&euro; (vingt-deux euros et quatre-vingt-dix centimes toutes taxes comprises). </span></li>
      </ul>
      <p className="c4"><span className="c8">- </span><span className="c0">1 000 (mille) lots de 1 (un) code promo contenant une remise imm&eacute;diate de 5&euro; TTC (cinq euros toutes taxes comprises), cette remise ne pouvant ensuite &ecirc;tre utilis&eacute;e que dans le respect des conditions d&eacute;taill&eacute;es ci-apr&egrave;s. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Les conditions d&rsquo;utilisation de ce code sont les suivantes : le code devra imp&eacute;rativement &ecirc;tre utilis&eacute; en France (Corse et Outre-Mer inclus), du 01/05/2024 au 31/12/2024 inclus, exclusivement sur le site internet www.nespresso.com, depuis l&rsquo;application mobile Nespresso, par t&eacute;l&eacute;phone au 0 800 55 52 53 (appel et service gratuits, disponibles du lundi au samedi de 8h &agrave; 20h, hors 1er mai et jours f&eacute;ri&eacute;s), ou dans l&rsquo;une de nos boutiques Nespresso (selon jours d&rsquo;ouverture de la boutique). La remise imm&eacute;diate de 5&euro; ne sera d&eacute;clench&eacute;e sur le montant de la commande de caf&eacute; qu&rsquo;&agrave; partir de 50 capsules de la gamme Original ou de la gamme Vertuo achet&eacute;es. Le code est strictement nominatif et non transf&eacute;rable, limit&eacute;e &agrave; une (1) utilisation par nouveau client (non enregistr&eacute; sur le site Internet www.nespresso.com ou en boutique Nespresso et ne disposant pas d&rsquo;un num&eacute;ro personnel du Club Nespresso) et non cumulable avec toute autre offre Nespresso ou Nestl&eacute; en cours. </span></p>
      <p className="c4"><span className="c0">Les lots ne sont ni remboursables, ni &eacute;changeables, ni cessibles, ni transmissibles. Toutes les images ou illustrations des lots utilis&eacute;es pour les besoins promotionnels de la pr&eacute;sente op&eacute;ration, et ce quel que soit le support utilis&eacute;, sont pr&eacute;sent&eacute;es &agrave; titre d&rsquo;illustrations et n&rsquo;ont aucune valeur contractuelle. &nbsp;</span></p>
      <p className="c4"><span className="c0">Dans l&rsquo;hypoth&egrave;se o&ugrave; un gagnant choisirait, en fonction du lot remport&eacute;, le lot de 20 &eacute;tuis de caf&eacute;s de la gamme Original ou de 5 &eacute;tuis de caf&eacute;s de la gamme Original, la Soci&eacute;t&eacute; Organisatrice ne lui remboursera pas la diff&eacute;rence avec le montant du lot de 20 &eacute;tuis de caf&eacute;s de la gamme Vertuo ou du lot de 5 &eacute;tuis de caf&eacute;s de la gamme Vertuo. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Les lots offerts ne peuvent donner lieu, de la part des gagnants, &agrave; aucune contestation d&#39;aucune sorte ni &agrave; la remise de leur contre-valeur sous quelque forme que ce soit, ni &agrave; leur remplacement ou &eacute;change pour quelque cause que ce soit, y compris en cas de force majeure emp&ecirc;chant les gagnants concern&eacute;s de b&eacute;n&eacute;ficier de leur lot. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">En cas de force majeure ou en cas de circonstances exceptionnelles ind&eacute;pendantes de sa volont&eacute;, la Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit de remplacer les lots gagn&eacute;s par des lots de valeur &eacute;quivalente ou sup&eacute;rieure. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Dans le cas o&ugrave; la Soci&eacute;t&eacute; Organisatrice ne serait pas en mesure de fournir les lots, sa responsabilit&eacute; ne pourrait pas &ecirc;tre engag&eacute;e. La Soci&eacute;t&eacute; Organisatrice d&eacute;cline toute responsabilit&eacute; quant &agrave; la qualit&eacute; et/ou &agrave; l&#39;&eacute;tat des lots &agrave; la livraison. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c2 c5">Article 5 : </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">ACCEPTATION DU PRESENT REGLEMENT / RESPONSABILITE</span></p>
      <p className="c4"><span className="c2">&nbsp;</span></p>
      <p className="c4"><span className="c14">Toute participation au Jeu implique l&rsquo;acceptation expresse et sans r&eacute;serve du pr&eacute;sent r&egrave;glement dans sa totalit&eacute; ainsi que de la &laquo; Politique de confidentialit&eacute; &raquo; de Nespresso qui peut &ecirc;tre consult&eacute;e &agrave; l&rsquo;adresse suivante : </span><span className="c18"><a className="c11" href="https://www.google.com/url?q=https://www.nespresso.com/fr/fr/legal&amp;sa=D&amp;source=editors&amp;ust=1685614572761731&amp;usg=AOvVaw27NYdItaKAI0iNfXyAa8Oz">https://www.nespresso.com/fr/fr/legal</a></span><span className="c5 c8">&nbsp;</span></p>
      <p className="c1"><span className="c5 c8"></span></p>
      <p className="c4"><span className="c0">Les enregistrements &eacute;lectroniques effectu&eacute;s sur le site internet du Jeu d&eacute;sign&eacute; &agrave; l&rsquo;article 2 font foi jusqu&acute;&agrave; preuve du contraire. </span></p>
      <p className="c4"><span className="c0">La participation au Jeu implique la connaissance et l&#39;acceptation des technologies et risques li&eacute;s &agrave; l&rsquo;utilisation ou &agrave; la connexion &agrave; Internet, notamment en ce qui concerne les performances techniques, les temps de r&eacute;ponse pour consulter, interroger ou transf&eacute;rer des informations, les risques d&#39;interruption, et plus g&eacute;n&eacute;ralement, les risques inh&eacute;rents &agrave; toute connexion et transmission, l&#39;absence de protection de certaines donn&eacute;es contre des d&eacute;tournements &eacute;ventuels et les risques de contamination par des &eacute;ventuels virus circulant sur le r&eacute;seau. </span></p>
      <p className="c4"><span className="c0">Il est express&eacute;ment rappel&eacute; qu&rsquo;Internet n&rsquo;est pas un r&eacute;seau s&eacute;curis&eacute;. La Soci&eacute;t&eacute; Organisatrice ne saurait donc &ecirc;tre tenue pour responsable de la contamination par d&rsquo;&eacute;ventuels virus ou de l&rsquo;intrusion d&rsquo;un tiers dans le syst&egrave;me du terminal des participants au Jeu. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice mettra tout en oeuvre pour permettre l&rsquo;acc&egrave;s au site du Jeu pendant toute la p&eacute;riode que durera celui-ci. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Pour autant, elle ne saurait &ecirc;tre tenue pour responsable en cas de dysfonctionnement du r&eacute;seau Internet ind&eacute;pendant de sa volont&eacute;. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">En cas de tel dysfonctionnement technique du Jeu ou du site Internet sur lequel il est propos&eacute;, la Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit, s&#39;il y a lieu, d&#39;invalider et/ou d&#39;annuler la session de Jeu au cours de laquelle le dit dysfonctionnement a eu lieu. Aucune r&eacute;clamation ne sera accept&eacute;e de ce fait. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice ne pourra &ecirc;tre tenue responsable si les donn&eacute;es relatives &agrave; l&#39;inscription d&#39;un participant ne lui parvenaient pas pour une quelconque raison dont elle ne pourrait &ecirc;tre tenue </span></p>
      <p className="c4"><span className="c0">responsable (par exemple, un probl&egrave;me de connexion &agrave; Internet d&ucirc; &agrave; une quelconque raison chez l&#39;utilisateur, une d&eacute;faillance momentan&eacute;e des serveurs de la Soci&eacute;t&eacute; Organisatrice ou de ses prestataires pour une raison quelconque, &hellip;) ou si elles lui parvenaient de fa&ccedil;on illisible ou impossible &agrave; traiter (par exemple, si le participant poss&egrave;de un mat&eacute;riel informatique ou un environnement logiciel inad&eacute;quat pour son inscription, &hellip;). </span></p>
      <p className="c4"><span className="c0">Toute information communiqu&eacute;e par les participants, notamment quant &agrave; leurs coordonn&eacute;es, sera consid&eacute;r&eacute;e comme nulle, au m&ecirc;me titre que leur participation, et ne sera pas prise en consid&eacute;ration si elle comporte une inexactitude ou si elle n&#39;est pas conforme aux dispositions du pr&eacute;sent r&egrave;glement (seconde inscription d&#39;un m&ecirc;me participant, etc.). </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice ne pourrait &ecirc;tre tenue responsable d&#39;un quelconque pr&eacute;judice (personnel, physique, mat&eacute;riel, financier ou autre) survenu &agrave; l&#39;occasion de la participation d&#39;un joueur au Jeu. </span></p>
      <p className="c4"><span className="c0">La responsabilit&eacute; de la Soci&eacute;t&eacute; Organisatrice ne saurait notamment &ecirc;tre encourue : </span></p>
      <p className="c4"><span className="c0">-Si un participant &eacute;tait d&eacute;connect&eacute; accidentellement par l&#39;op&eacute;rateur t&eacute;l&eacute;phonique ou son fournisseur d&#39;acc&egrave;s Internet, </span></p>
      <p className="c4"><span className="c0">-Si un participant oubliait de saisir int&eacute;gralement ses coordonn&eacute;es, </span></p>
      <p className="c4"><span className="c0">-Si un participant subissait une panne technique quelconque (mauvais &eacute;tat de la ligne, du combin&eacute;), </span></p>
      <p className="c4"><span className="c0">-Si une d&eacute;faillance technique (ind&eacute;pendante de la volont&eacute; de la Soci&eacute;t&eacute; Organisatrice) du serveur du site internet du Jeu emp&ecirc;chait un participant d&#39;acc&eacute;der au formulaire de participation, </span></p>
      <p className="c4"><span className="c0">-En cas de panne &eacute;lectrique ou d&#39;incident du serveur du site internet du Jeu. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">En cons&eacute;quence, la Soci&eacute;t&eacute; Organisatrice ne saurait en aucune circonstance &ecirc;tre tenue responsable, sans que cette liste soit limitative : </span></p>
      <p className="c4"><span className="c0">- Du contenu des services consult&eacute;s sur le Site et, de mani&egrave;re g&eacute;n&eacute;rale, de toutes informations et/ou donn&eacute;es diffus&eacute;es sur les services consult&eacute;s sur le Site, </span></p>
      <p className="c4"><span className="c0">- De la transmission et/ou de la r&eacute;ception de toute donn&eacute;e et/ou information sur Internet, </span></p>
      <p className="c4"><span className="c0">- De tout dysfonctionnement du r&eacute;seau Internet emp&ecirc;chant le bon d&eacute;roulement/fonctionnement du Jeu, </span></p>
      <p className="c4"><span className="c0">- De la d&eacute;faillance de tout mat&eacute;riel de r&eacute;ception ou des lignes de communication, </span></p>
      <p className="c4"><span className="c0">- De la perte de tout courrier papier ou &eacute;lectronique et, plus g&eacute;n&eacute;ralement, de perte de toute donn&eacute;e, </span></p>
      <p className="c4"><span className="c0">- Des probl&egrave;mes d&#39;acheminement, </span></p>
      <p className="c4"><span className="c0">- Du fonctionnement de tout logiciel, </span></p>
      <p className="c4"><span className="c0">- Des cons&eacute;quences de tout virus, bogue informatique, anomalie, d&eacute;faillance technique, </span></p>
      <p className="c4"><span className="c0">- De tout dommage caus&eacute; &agrave; l&rsquo;ordinateur d&#39;un participant, </span></p>
      <p className="c4"><span className="c0">- De toute d&eacute;faillance technique, mat&eacute;rielle et logicielle de quelque nature, ayant emp&ecirc;ch&eacute; ou limit&eacute; la possibilit&eacute; de participer au Jeu ou ayant endommag&eacute; le syst&egrave;me informatique d&#39;un participant, </span></p>
      <p className="c4"><span className="c0">- De l&rsquo;annulation ou du report de l&rsquo;&eacute;v&egrave;nement </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Il appartient &agrave; tout participant de prendre toutes les mesures appropri&eacute;es de fa&ccedil;on &agrave; prot&eacute;ger ses propres donn&eacute;es et/ou logiciels stock&eacute;s sur son &eacute;quipement informatique contre toute atteinte. La connexion de toute personne au Site et la participation au Jeu se fait sous leur enti&egrave;re responsabilit&eacute;. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Il est convenu que la Soci&eacute;t&eacute; Organisatrice pourra se pr&eacute;valoir, notamment aux fins de preuve de tout acte, fait ou omission, des programmes, donn&eacute;es, fichiers, enregistrements, op&eacute;rations et autres &eacute;l&eacute;ments (tel que des rapports de suivi ou autres &eacute;tats) de nature ou sous format ou support informatiques ou &eacute;lectroniques, &eacute;tablis, re&ccedil;us ou conserv&eacute;s directement ou indirectement par la Soci&eacute;t&eacute; Organisatrice, notamment dans ses syst&egrave;mes d&#39;information, en rapport avec l&#39;utilisation de son Site Internet. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Les participants s&#39;engagent &agrave; ne pas contester la recevabilit&eacute;, la validit&eacute; ou la force probante des &eacute;l&eacute;ments de nature ou sous format ou supports informatiques ou &eacute;lectroniques pr&eacute;cit&eacute;s, sur le fondement de quelque disposition l&eacute;gale que ce soit et qui sp&eacute;cifierait que certains documents doivent &ecirc;tre &eacute;crits ou sign&eacute;s par les parties pour constituer une preuve. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Ainsi, les &eacute;l&eacute;ments consid&eacute;r&eacute;s constituent des preuves et, s&#39;ils sont produits comme moyens de preuve par la Soci&eacute;t&eacute; Organisatrice dans toute proc&eacute;dure contentieuse ou autre, ils seront recevables, valables </span></p>
      <p className="c4"><span className="c0">et opposables entre les parties de la m&ecirc;me mani&egrave;re, dans les m&ecirc;mes conditions et avec la m&ecirc;me force probante que tout document qui serait &eacute;tabli, re&ccedil;u ou conserv&eacute; par &eacute;crit. </span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit d&#39;exclure de la participation au Jeu toute personne troublant le d&eacute;roulement du Jeu. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice se r&eacute;serve la facult&eacute; d&#39;utiliser tout recours et notamment de poursuivre en justice quiconque aurait trich&eacute;, fraud&eacute;, truqu&eacute; ou troubl&eacute; les op&eacute;rations d&eacute;crites dans le pr&eacute;sent r&egrave;glement, ou, aurait tent&eacute; de le faire. </span></p>
      <p className="c4"><span className="c0">Tout participant au Jeu qui serait consid&eacute;r&eacute; par la Soci&eacute;t&eacute; Organisatrice comme ayant troubl&eacute; le Jeu d&rsquo;une quelconque des mani&egrave;res pr&eacute;cit&eacute;es, sera de plein droit d&eacute;chu de tout droit &agrave; obtenir un quelconque lot, aucune r&eacute;clamation ne sera accept&eacute;e de ce fait. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">Article 6 : PROTECTION DES DONNEES A CARACTERE PERSONNEL</span></p>
      <p className="c4"><span className="c2">&nbsp;</span></p>
      <p className="c4"><span className="c0">Les participants autorisent aussi la Soci&eacute;t&eacute; Organisatrice &agrave; diffuser leur nom, pr&eacute;nom et commune de r&eacute;sidence dans le cas o&ugrave; ils seraient d&eacute;sign&eacute;s gagnants. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Ces donn&eacute;es pourront &ecirc;tre utilis&eacute;es par la Soci&eacute;t&eacute; Organisatrice &agrave; des fins publi-promotionnelles dans toutes les manifestations li&eacute;es au Jeu, sur tout support de communication et ce pour une dur&eacute;e de 3 (trois) mois maximum. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Par ailleurs, les donn&eacute;es personnelles collect&eacute;es sont n&eacute;cessaires &agrave; l&rsquo;organisation du Jeu et notamment &agrave; la prise en compte de la participation et &agrave; l&rsquo;attribution des lots aux gagnants. Elles sont exclusivement destin&eacute;es &agrave; la Soci&eacute;t&eacute; Organisatrice dans le cadre du Jeu et ne seront pas utilis&eacute;es &agrave; d&rsquo;autres fins, sauf accord des participants. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c14">Elles seront conserv&eacute;es pendant la dur&eacute;e n&eacute;cessaire &agrave; la gestion du Jeu, soit 12 mois apr&egrave;s la fin de ce dernier. Dans le cadre de leur traitement, ces donn&eacute;es pourront &ecirc;tre transmises &agrave; des sous-traitants situ&eacute;s hors de l&#39;Union Europ&eacute;enne. Pour en savoir plus, les participants peuvent consulter la &laquo; </span><span className="c16">Politique Nespresso sur la Protection des Donn&eacute;es Personnelles </span><span className="c0">&raquo;. Conform&eacute;ment &agrave; la r&eacute;glementation, les participants disposent d&rsquo;un droit d&rsquo;acc&egrave;s, de rectification et de suppression des informations les concernant qu&rsquo;ils peuvent exercer en &eacute;crivant &agrave; : </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c3"><span className="c0">Nespresso France</span></p>
      <p className="c3"><span className="c0">Service Protection des donn&eacute;es personnelles</span></p>
      <p className="c3"><span className="c0">TSA 71623</span></p>
      <p className="c3"><span className="c0">75901 Paris Cedex 15</span></p>
      <p className="c3 c19"><span className="c0"></span></p>
      <p className="c4"><span className="c14">En cas de r&eacute;clamation non r&eacute;solue directement avec la Soci&eacute;t&eacute; Organisatrice, les participants peuvent s&rsquo;adresser &agrave; la CNIL (</span><span className="c15"><a className="c11" href="https://www.google.com/url?q=https://www.cnil.fr/fr/plaintes&amp;sa=D&amp;source=editors&amp;ust=1685614572768636&amp;usg=AOvVaw0BVwQMhNzD7TanmQLl7RMK">https://www.cnil.fr/fr/plaintes</a></span><span className="c0">). </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">Article 7 : MODALITES DE MODIFICATION DU JEU </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">En cas de force majeure ou en cas de circonstances exceptionnelles ind&eacute;pendantes de sa volont&eacute;, la Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit d&rsquo;&eacute;courter, modifier, prolonger, suspendre ou annuler le pr&eacute;sent Jeu, sans pr&eacute;avis, en partie ou dans son ensemble. Sa responsabilit&eacute; ne saurait &ecirc;tre engag&eacute;e de ce fait et aucun d&eacute;dommagement ne pourra &ecirc;tre demand&eacute; par les participants. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Ces changements pourront faire toutefois l&#39;objet d&#39;une information pr&eacute;alable par tous les moyens appropri&eacute;s, notamment via le site Internet de la Soci&eacute;t&eacute; Organisatrice. </span></p>
      <p className="c4"><span className="c0">La Soci&eacute;t&eacute; Organisatrice se r&eacute;serve la possibilit&eacute; de suspendre momentan&eacute;ment la possibilit&eacute; de participer au Jeu si elle, ou son &eacute;ventuel prestataire d&#39;h&eacute;bergement, ne peuvent plus assurer la continuit&eacute; du service n&eacute;cessaire au d&eacute;roulement du Jeu. </span></p>
      <p className="c4"><span className="c0">Des modifications &agrave; ce r&egrave;glement peuvent &eacute;ventuellement intervenir pendant le Jeu. Elles feront l&rsquo;objet d&rsquo;avenants au pr&eacute;sent r&egrave;glement mis en ligne sur le site internet de la Soci&eacute;t&eacute; Organisatrice et d&eacute;pos&eacute;s &agrave; l&#39;Etude d&#39;huissier d&eacute;positaire du pr&eacute;sent r&egrave;glement. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">La date du tirage au sort pourra notamment &ecirc;tre d&eacute;cal&eacute;e. Par ailleurs, la Soci&eacute;t&eacute; Organisatrice ne saurait &ecirc;tre tenue pour responsable de faits qui ne lui seraient pas imputables, notamment un &eacute;ventuel retard dans la livraison des lots, ou en cas de force majeure susceptible de perturber, modifier ou annuler le Jeu. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">Article 8 : DEPOT ET COMMUNICATION DU PRESENT REGLEMENT </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Le pr&eacute;sent r&egrave;glement est d&eacute;pos&eacute; aupr&egrave;s de l&rsquo;&eacute;tude THOMAZON-AUDRANT-BICHE, huissiers de justice associ&eacute;s &ndash; 156 rue Montmartre 75002 Paris. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c14">Il peut &ecirc;tre consult&eacute; sur le site </span><span className="c8">/https://jeu-cafe.nespresso.com/jeu/ </span><span className="c0">et adress&eacute; &agrave; titre gratuit &agrave; toute personne qui en fait la demande jusqu&rsquo;au 30 juin 2024 inclus (cachet de La Poste faisant foi) aupr&egrave;s de : Nespresso France SAS 27 &ndash; 33 rue du Colonel Pierre Avia - 75015 PARIS (remboursement du timbre au tarif lent en vigueur sur simple demande). </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Pour obtenir le remboursement des timbres utilis&eacute;s, le participant devra joindre &agrave; son envoi : </span></p>
      <p className="c4"><span className="c0">- ses coordonn&eacute;es compl&egrave;tes (nom, pr&eacute;nom, adresse, code postal, ville) </span></p>
      <p className="c4"><span className="c0">- un RIB (Relev&eacute; d&#39;Identit&eacute; Bancaire) ou un RIP (Relev&eacute; d&#39;Identit&eacute; Postal). </span></p>
      <p className="c4"><span className="c0">Aucune demande de remboursement de timbre ne pourra &ecirc;tre honor&eacute;e si les conditions indiqu&eacute;es ci-dessus ne sont pas remplies ou si la demande est effectu&eacute;e par t&eacute;l&eacute;phone. </span></p>
      <p className="c4"><span className="c0">Les remboursements seront effectu&eacute;s dans un d&eacute;lai de 4 semaines &agrave; partir de la r&eacute;ception de la demande &eacute;crite. Le remboursement des frais d&#39;affranchissement engag&eacute;s pour l&#39;envoi de la demande de r&egrave;glement est limit&eacute; &agrave; un seul remboursement par foyer (m&ecirc;me nom, m&ecirc;me adresse). </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c5 c2">Article 9 : CONTESTATIONS ET RECLAMATIONS </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">Pour &ecirc;tre prises en compte, les &eacute;ventuelles contestations ou r&eacute;clamations relatives au Jeu doivent &ecirc;tre formul&eacute;es sur demande &eacute;crite &agrave; l&rsquo;adresse suivante : Nespresso France SAS - 27 &ndash; 33 rue du Colonel Pierre Avia - 75015 PARIS avant le 30 novembre 2024 (le cachet de la Poste faisant foi). </span></p>
      <p className="c4"><span className="c0">Cette lettre devra indiquer la date pr&eacute;cise de participation au Jeu, les coordonn&eacute;es compl&egrave;tes du participant et le motif exact de la contestation. Aucun autre mode de contestation ou de r&eacute;clamation ne pourra &ecirc;tre pris en compte. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c4"><span className="c0">En cas de d&eacute;saccord persistant sur l&#39;application ou l&#39;interpr&eacute;tation du pr&eacute;sent r&egrave;glement, et &agrave; d&eacute;faut d&#39;accord amiable sous trente (30) jours, le participant pourra saisir au choix : </span></p>
      <p className="c4"><span className="c0">- la juridiction du lieu o&ugrave; est &eacute;tabli le d&eacute;fendeur </span></p>
      <p className="c4"><span className="c0">- la juridiction dont d&eacute;pend son domicile. </span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c1"><span className="c0"></span></p>
      <p className="c17"><span className="c14">Fait &agrave; Paris, le 08/04/2024.</span></p>
  </div>
  </>);
  }


  export default Reglement;