
export default `Tarte Citron,Origin,Black Espresso,Cosi,Toccanto
Tarte Citron,Origin,Black Espresso,Colombia,Voltesso
Tarte Citron,Origin,Black Espresso,Peru Organic,Peru Organic
Tarte Citron,Origin,Lait,Chiaro,Bianco Piccolo
Tarte Citron,Origin,Lait,Peru Organic,Bianco Doppio
Tarte Citron,Origin,Lait,Colombia,Bianco Forte
Tarte Citron,Origin,Black Long,Ethiopia,Ethiopia
Tarte Citron,Origin,Black Long,Colombia,Colombia
Tarte Citron,Origin,Black Long,Peru Organic,Solelio
Tarte Citron,RSE,Black Espresso,Peru Organic,Toccanto
Tarte Citron,RSE,Black Espresso,Volluto,Voltesso
Tarte Citron,RSE,Black Espresso,Ethiopia,Peru Organic
Tarte Citron,RSE,Lait,Cosi,Bianco Piccolo
Tarte Citron,RSE,Lait,Colombia,Bianco Doppio
Tarte Citron,RSE,Lait,Peru Organic,Bianco Forte
Tarte Citron,RSE,Black Long,Tokyo Lungo,Ethiopia
Tarte Citron,RSE,Black Long,Shanghai Lungo,Colombia
Tarte Citron,RSE,Black Long,Ethiopia,Kahawa ya Congo
Tarte Citron,Torre,Black Espresso,Volluto,Toccanto
Tarte Citron,Torre,Black Espresso,Cosi,Voltesso
Tarte Citron,Torre,Black Espresso,Ethiopia,Peru Organic
Tarte Citron,Torre,Lait,Volluto,Bianco Piccolo
Tarte Citron,Torre,Lait,Chiaro,Bianco Doppio
Tarte Citron,Torre,Lait,Cosi,Bianco Forte
Tarte Citron,Torre,Black Long,Tokyo Lungo,Solelio
Tarte Citron,Torre,Black Long,Shanghai Lungo,Inizio
Tarte Citron,Torre,Black Long,Ethiopia,Ethiopia
Flan patissier,Origin,Black Espresso,Nicaragua,Dolce
Flan patissier,Origin,Black Espresso,Paris Espresso,Orafio
Flan patissier,Origin,Black Espresso,Livanto,Voltesso
Flan patissier,Origin,Lait,Scuro,Bianco Piccolo
Flan patissier,Origin,Lait,Nicaragua,Bianco Doppio
Flan patissier,Origin,Lait,Livanto,Bianco Forte
Flan patissier,Origin,Black Long,Vienna Lungo,Costa Rica
Flan patissier,Origin,Black Long,Nicaragua,Melozio
Flan patissier,Origin,Black Long,Buenos Aires Lungo,Arondio
Flan patissier,RSE,Black Espresso,Café Joyeux,Dolce
Flan patissier,RSE,Black Espresso,Paris Espresso,Orafio
Flan patissier,RSE,Black Espresso,Livanto,Voltesso
Flan patissier,RSE,Lait,Livanto,Bianco Piccolo
Flan patissier,RSE,Lait,Paris Espresso,Bianco Doppio
Flan patissier,RSE,Lait,Café Joyeux,Bianco Forte
Flan patissier,RSE,Black Long,Nicaragua,Costa Rica
Flan patissier,RSE,Black Long,Vienna Lungo,Melozio
Flan patissier,RSE,Black Long,Café Joyeux,Arondio
Flan patissier,Torre,Black Espresso,Capriccio,Dolce
Flan patissier,Torre,Black Espresso,Paris Espresso,Orafio
Flan patissier,Torre,Black Espresso,Livanto,Voltesso
Flan patissier,Torre,Lait,Scuro,Bianco Piccolo
Flan patissier,Torre,Lait,Nicaragua,Bianco Doppio
Flan patissier,Torre,Lait,Capriccio,Bianco Forte
Flan patissier,Torre,Black Long,Vienna Lungo,Costa Rica
Flan patissier,Torre,Black Long,Buenos Aires Lungo,Melozio
Flan patissier,Torre,Black Long,Nicaragua,Arondio
Fondant chocolat,Origin,Black Espresso,Indonesia,Scuro
Fondant chocolat,Origin,Black Espresso,India,Altissio
Fondant chocolat,Origin,Black Espresso,Kahawa Ya Congo,Diavolitto
Fondant chocolat,Origin,Lait,Corto,Bianco Piccolo
Fondant chocolat,Origin,Lait,Roma,Bianco Doppio
Fondant chocolat,Origin,Lait,Ristretto,Bianco Forte
Fondant chocolat,Origin,Black Long,Indonesia,Mexico
Fondant chocolat,Origin,Black Long,India,Fortado
Fondant chocolat,Origin,Black Long,Kahawa Ya Congo,Odacio
Fondant chocolat,RSE,Black Espresso,Kahawa Ya Congo,Il Caffé
Fondant chocolat,RSE,Black Espresso,Indonesia,Chiaro
Fondant chocolat,RSE,Black Espresso,Arpeggio,Altissio
Fondant chocolat,RSE,Lait,Corto,Bianco Piccolo
Fondant chocolat,RSE,Lait,India,Bianco Doppio
Fondant chocolat,RSE,Lait,Roma,Bianco Forte
Fondant chocolat,RSE,Black Long,Kahawa Ya Congo,Mexico
Fondant chocolat,RSE,Black Long,Indonesia,Fortado
Fondant chocolat,RSE,Black Long,India,Stormio
Fondant chocolat,Torre,Black Espresso,Napoli,Il Caffé
Fondant chocolat,Torre,Black Espresso,Ristretto,Scuro
Fondant chocolat,Torre,Black Espresso,Venezia,Diavolitto
Fondant chocolat,Torre,Lait,Buenos Aires Lungo,Bianco Piccolo
Fondant chocolat,Torre,Lait,Rio,Bianco Doppio
Fondant chocolat,Torre,Lait,Istanbul,Bianco Forte
Fondant chocolat,Torre,Black Long,Stockholm,Fortado
Fondant chocolat,Torre,Black Long,Indonesia,Odacio
Fondant chocolat,Torre,Black Long,India,Stormio`