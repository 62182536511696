import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { SpinnerDotted  } from 'spinners-react';
import "./PopIn.scss"
import Button from './Button';


interface OwnProps {
    children?: React.ReactNode;
    show: boolean;
    onHide: () => void;
    fullscreen?: boolean
    size?: 'sm' | 'lg' | 'xl';
 }
const PopIn: React.FC<OwnProps> = ({children, show, size = undefined, fullscreen = false, onHide}) => {

    useEffect(() => {
        
    }, [])

    return (<Modal
        className='popin'
        dialogClassName={fullscreen ? "modal-fullscreen-md-down" : ""}
        aria-labelledby="contained-modal-title-vcenter"
        size={size}
        centered
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Fermer</Button>
        </Modal.Footer>
      </Modal>)



   
}

export default PopIn
