import { Q1Responses } from "pages/Question1";
import { Q2Responses } from "pages/Question2";
import { Q3Responses } from "pages/Question3";
import GetCoffee, { ICoffee, CoffeesList } from "./CoffeesList";
import CoffeeSelector from "./CoffeeSelector";

export interface IQuizzResult{
    original:ICoffee[],
    vertuo:ICoffee[],
}

interface csvRow{
    Response1: Q1Responses;
    Response2: Q2Responses;
    Response3: Q3Responses;
    ValueOriginal: string;
    ValueVertuo: string;
}

export default function getQuizzResultat(r1: string, r2: string, r3: string){
    


    // for testing
    if(!r1 && !r2 && !r3)
    {
        return {
            original: CoffeesList.filter(m => m.type == "original"),
            vertuo: CoffeesList.filter(m => m.type == "vertuo")
        } as IQuizzResult;
    }


    const dataRows: string[] = CoffeeSelector.trim().split('\n');
    const dataArray: csvRow[] = dataRows.map((row: string) => {
        const allCols = row.split(',');

        return {
            Response1: allCols[0] as Q1Responses,
            Response2: allCols[1] as Q2Responses,
            Response3: allCols[2] as Q3Responses,
            ValueOriginal: allCols[3] as string,
            ValueVertuo: allCols[4] as string,
        } as csvRow

    });

    // test all coffee are ok
    // décommaneter pour tester que tous les caffé du csv existent bien
    /*dataArray.map((row) => {
        GetCoffee(row.ValueOriginal);
        GetCoffee(row.ValueVertuo);
    })*/


    
    const result = dataArray.filter(m => m.Response1.toLocaleLowerCase() == r1.toLocaleLowerCase() && m.Response2.toLocaleLowerCase() == r2.toLocaleLowerCase() && m.Response3.toLocaleLowerCase() == r3.toLocaleLowerCase());

    console.log("getQuizzResultat", result)
    const resultOriginal = result.map(c => GetCoffee(c.ValueOriginal, 'original'));
    const resultVertuo = result.map(c => GetCoffee(c.ValueVertuo, 'vertuo'));
    return {
        original: resultOriginal,
        vertuo: resultVertuo
    } as IQuizzResult;
}