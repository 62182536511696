import moment from "moment";

export default {
    dateFromLong: "1er mai 2024",
    dateFromShort: "01/05/2024",
    dateFromMoment: moment("2024-03-01 06:00:00"),
    
    dateToLong: "30 mai 2024",
    dateToShort: "30/05/2024",
    dateToMoment: moment("2024-05-30 23:59:00"),
}