import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Question, { IQuestionData, IResponse } from 'components/Question';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import Header from 'components/Header';
import './SurveyResultatWinner.scss';
import getQuizzResultat, { IQuizzResult } from 'resources/getQuizzResultat';
import { ICoffee } from 'resources/CoffeesList';
import Button from 'components/Button';
import { useAppSelector, useAppDispatch } from 'store/store';
import { setShowReglementModal, setSurveyPrice, surveyResultPriceType } from 'store/reducers/espressoJeuReducer';
import gameDates from 'resources/gameDates';

const SurveyResultatWinner: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const simulatePrice = searchParams.get("simulatePrice");
    
    const surveyResultPrice = useAppSelector(state => state.espressoJeu.surveyPrice);
    const surveyResultPriceRemiseCode = useAppSelector(state => state.espressoJeu.surveyPriceRemiseCode);
    const user = useAppSelector(state => state.espressoJeu.signUpResult);
    
    console.log("useEffect surveyResultPrice", surveyResultPrice)
    useLayoutEffect (() => {
        document.body.scrollTo(0,0)
    }, [])

    useEffect(() => {

        if(!simulatePrice && !user)
        {
            navigate(ROUTES.JEU_INSCRIPTION_ROUTE);
        }


        if(simulatePrice)
        {
            if(simulatePrice == "5eRemise") dispatch(setSurveyPrice('5eRemise'));
            else if (simulatePrice == "200Capsules") dispatch(setSurveyPrice('200Capsules'));
            else if (simulatePrice == "50Capsules") dispatch(setSurveyPrice('50Capsules'));
        }
        

        if(!surveyResultPrice)
        {
            //todo décommenter
            navigate(ROUTES.JEU_INSCRIPTION_ROUTE)
        }

        document.getElementsByTagName("body")[0].style.setProperty("overflow", "auto");
        return () => {
            document.getElementsByTagName("body")[0].style.setProperty("overflow", "hidden");
        }

    }, [])


    let title = <></>;
    let description = <></>;

    switch (surveyResultPrice) {
        case '200Capsules':
            title = <>
                    <div className='win-text'><b>Un lot de <span className='fs-2'>200 CAPSULES</span> de café</b></div>
                    <div className='win-text'><b>Sélectionnées par Nespresso</b></div>
                </>;
            description = <>
                <div>Le Service Client Nespresso prendra contact avec vous</div>
                <div>dans les 4 prochaines semaines après la fin du jeu pour vous envoyer votre gain.</div>
                <div className='mt-3'>Vous pourrez choisir parme un assortiment prédéfini de la gamme Vertuo ou de la gamme Original.</div>
            </>
            break;
        case '50Capsules':
            title = <>
                    <div className='win-text'><b>Un lot de <span className='fs-2'>50 CAPSULES</span> de café</b></div>
                    <div className='win-text'><b>Sélectionnées par Nespresso</b></div>
                </>
            description = <>
                    <div>Le Service Client Nespresso prendra contact avec vous</div>
                    <div>dans les 4 prochaines semaines après la fin du jeu pour vous envoyer votre gain.</div>
                    <div className='mt-3'>Vous pourrez choisir parme un assortiment prédéfini de la gamme Vertuo ou de la gamme Original.</div>
                </>
            break;
        case '5eRemise':
            title = <>
                    <div className='win-text fs-3'><b>5€ de remise*</b></div>
                    <div className='win-text'>Bénéficiez de votre remise avec votre code privilège</div>
                    <div className='win-text'>{surveyResultPriceRemiseCode}</div>
                </>
            description = <>
                <div>Code à saisir lors de votre commande, valable dès 50 capsules commandées.</div>
                <div>*Réservé aux nouveaux clients exclusivement.</div>
            </>
            break;
        default:
            break;
    }


    return <>
         <div id="resultat-win-page" style={{ position: 'relative', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            
            <div id="panel-header">
                <Header />
            </div>
            <Container id='container'>
                <div id="panel-result" className='bg-white pt-5 pb-5 text-center'>
                    <div id="win-panel" className='p-5'>
                        <div className='win-text fs-4'><b>Félicitation, vous avez remporté</b></div>
                        {title}
                    </div>

                    
                    <div className='mt-4'>
                        {description}
                    </div>


                    <div className='mt-4'>
                        <div><a className='link' href="#" onClick={() => dispatch(setShowReglementModal(true))}>Voir le réglement</a></div>
                    </div>

                    
                </div>
                <div id="footer-win">
                    <Row>
                        <Col className='mt-2'>
                            <div>Rendez-vous sur notre site dès maintenant pour découvrir</div>
                            <div>Toutes les actualités Nespresso et les offres du moment.</div>
                        </Col>
                        <Col xs="12" sm="12" md="4" className='mt-2'><Button className="win-btn w-100" onClick={() => window.open('https://www.nespresso.com','_blank')} >www.nespresso.com</Button></Col>
                    </Row>
                </div>  
            </Container>
            
        </div>
        <div id="footer-legal-information">
                <div className='text-center'>*Champs obligatoire</div>

                <div className='text-center fs-11px' style={{fontFamily: "inherit"}}>{`«Jeu sans obligation d'achat organisé par la société Nespresso France, ouvert du ${gameDates.dateFromShort} à 6h00
    au ${gameDates.dateToShort} à 23h59 inclus, réservé exclusivement aux nouveaux clients Nespresso (personne
    physique majeure, non enregistrée sur le site www.nespresso.com ou en boutique Nespresso et ne
    disposant pas d'un numéro personnel du Club Nespresso), résidant en France (Corse et Outre-Mer
    inclus) et limité à 1 participation par personne. Pour participer, se rendre sur le site www.grand-jeu-
    nespresso.com et suivre les étapes de participation indiquées. Mise en jeu de 1 lot de 20 étuis de
    cafés prédéterminés par Nespresso au choix entre les gammes Original (valeur estimative: 90,50 €
    TTC) ou Vertuo (valeur estimative: 107,80 € TTC), de 3 lots de 5 étuis de cafés prédéterminés par
    Nespresso au choix entre les gammes Original (valeur estimative: 23,30 € TTC) ou Vertuo (valeur
    estimative: 26 € TTC) et de 1000 lots de 1 code promo contenant une remise immédiate de 5€ TTC,
    utilisable ensuite dès l'achat de 50 capsules Vertuo ou Original (voir conditions d'utilisation du code
    dans l'email de confirmation du lot et dans le règlement). Voir modalités complètes sur www.grand-
    jeu-nespresso.com. Le règlement peut également être adressé sur simple demande formulée à
    Nespresso France SAS 27 - 33 rue du Colonel Pierre Avia - 75015 PARIS avant le 15/10/2023»`}
                    </div>
            </div>
    </>
}

export default SurveyResultatWinner