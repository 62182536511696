import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Question, { IQuestionData, IResponse } from 'components/Question';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { setReponseQ2 } from 'store/reducers/espressoJeuReducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import useUrlType from 'hooks/useUrlType';


export enum Q2Responses {
    Origin = 'Origin',
    Rse = 'Rse',
    Torre = 'Torre'
}


const Question2: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const responseQ1 = useAppSelector(state => state.espressoJeu.reponseQ1);
    const urlType = useUrlType();
    
    useLayoutEffect (() => {
        document.body.scrollTo(0,0)
    }, [])
    
    useEffect(() => {
        if(!responseQ1)
        {
            navigate(urlType == "selector" ? ROUTES.SELECTOR_QUESTION1_ROUTE : ROUTES.JEU_QUESTION1_ROUTE)
        }
    }, [])



    const onSelectResponse = (id: string) =>{
        dispatch(setReponseQ2(id));
        navigate(urlType == "selector" ? ROUTES.SELECTOR_QUESTION3_ROUTE : ROUTES.JEU_QUESTION3_ROUTE)
    }

    return <>
        <Question 
            onSelectResponse={onSelectResponse}
            currentIndexQuestion={2}
            totalQuestion={3}
            popinTitle='Nespresso vous propose une gamme de plus de 60 cafés'
            popinContent={<Container>
                <Row>
                    <Col sm="12" md="12" lg="6">
                    <div><h2 className='text-uppercase'><b>Avec Nespresso, vous {"n'êtes"} jamais au bout de vos surprises.</b></h2></div>
                        <div>Vous souhaitez explorer de nouvelles saveurs? Nespresso vous propose plus de 60 variétés de cafés pour répondre à vos envies de chaque moment de dégustation.</div>
                        <div className='mt-4'><img src="/img/visuel_pop_2_1.jpg" style={{width:"100%", height:"500px", objectFit:"cover"}}></img></div>
                    </Col>
                    <Col sm="12" md="12" lg="6">
                        <div className='d-none d-lg-block'><img src="/img/visuel_pop_2_2.jpg" style={{maxWidth:"100%"}}></img></div>
                        <div className='mt-4'><h5 className='text-uppercase'><b>Du choix à la simplicité</b></h5></div>
                        <div className='mt-4'>Nespresso vous propose plus de 60 cafés provenant de plus de 18 pays {"d'origine"}, vous permettant de trouver le café qui vous ressemble. {"D'une"} simple touche, et en quelques secondes, vous avez la garantie {"d'un"} café de qualité et qui répond parfaitement à votre envie du moment.</div>
                        <div className='mt-4'><h5 className='text-uppercase'><b>Explorez, dégustez & savourez</b></h5></div>
                        <div className='mt-4'>Que vous le préfériez court ou long, intense ou décafeiné, <b>vous savourez un café selon votre envie du moment</b> et vivez une expérience de dégustation unique. Pour vous proposer de nouvelles expériences tout au long de {"l'année"}, notre gamme {"s'élargit"} avec nos Editions Limitées au savoir-faire spécifique.</div>
                        <div className='mt-4'><h5 className='text-uppercase'><b>Réinventez votre tasse de café avec nos recettes</b></h5></div>
                        <div className='mt-4'>Un café Nespresso, {"c'est"} un équilibre subtil. {"Lorsqu'il"} est repensé par nos Baristas, il dévoile alors des saveurs inédites. Laissez-vous tenter par un cappuccino classique ou un cocktail rafraichissant. <br/> Amusez-vous !</div>
                        <div className='mt-4'><a href="https://www.nespresso.com/fr/fr/recettes" target='blank'>https://www.nespresso.com/fr/fr/recettes</a></div>
                    </Col>
                </Row>
            </Container>}
            data={{
                question:"Votre café, vous aimez le déguster en format ?",
                responses:[
                    {id:Q2Responses.Origin, image:"/img/Q2_1.jpg",  title:"Espresso", subTible:"Un café court pour découvrir le véritable espresso, l'âme même du café"} as IResponse,
                    {id:Q2Responses.Rse, image:"/img/Q2_2.jpg",  title:"Lungo", subTible:"Un café spécialement conçu par les Experts Café pour être dégusté dans une grande tasse"} as IResponse,
                    {id:Q2Responses.Torre, image:"/img/Q2_3.jpg",  title:"Lait et création gourmande", subTible:"Réinventez votre café avec les recettes Baristas pour découvrir des saveurs inédites et gourmandes"} as IResponse
                ],
                
        } as IQuestionData}/>
    </>
}

export default Question2