import React  from 'react';
import "./Header.scss";
import logoSvg from './../resources/logo-white.svg'
import useUrlType from 'hooks/useUrlType';

interface OwnProps {
    subTitle?: string | undefined
}
const Header: React.FC<OwnProps> = ({subTitle = undefined}) => {
    const urlType = useUrlType();
    
    let prefixTitle = "Grand jeu";

    if(urlType == "selector")
    {
        prefixTitle = "Découvrez"
    }

    return (<div id='header'>
        <img id="logo" src={logoSvg}/>
        <h2 className='text-uppercase mt-3'><b className='font-bold'>{prefixTitle}</b> votre café nespresso préféré</h2>
        {subTitle && <h2>{subTitle}</h2>}
    </div>);
}

export default Header
