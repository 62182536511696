


export const ROUTES = {
    HOME: '/',
    SELECTOR_HOME: '/selector',
    SELECTOR_QUESTION1_ROUTE: '/selector/question-1',
    SELECTOR_QUESTION2_ROUTE: '/selector/question-2',
    SELECTOR_QUESTION3_ROUTE: '/selector/question-3',
    SELECTOR_RESULTAT_SURVEY_ROUTE: '/selector/resultat',

    JEU_INSCRIPTION_ROUTE: '/jeu/',
    JEU_QUESTION1_ROUTE: '/jeu/question-1',
    JEU_QUESTION2_ROUTE: '/jeu/question-2',
    JEU_QUESTION3_ROUTE: '/jeu/question-3',
    JEU_RESULTAT_SURVEY_ROUTE: '/jeu/resultat',
    JEU_GAME_WIN_ROUTE: '/jeu/resultat-gagne',
    JEU_GAME_LOST_ROUTE: '/jeu/resultat-perdu',
    JEU_GAME_ALREADY_PLAYED_ROUTE: '/jeu/deja-joue',
    JEU_COMING_SOON: '/jeu/coming-soon',
    JEU_GAME_OVER: '/jeu/jeu-termine'
}