import { createAction, createReducer } from '@reduxjs/toolkit'
import ISignUpResult from 'models/ISignUpResult';


/** Les prix gagnants */
export type surveyResultPriceType = '200Capsules' | '50Capsules' | '5eRemise' | 'Perdu' | 'DejaJoue';

interface espressoJeuData {
    signUpResult?: ISignUpResult;
    reponseQ1?: string;
    reponseQ2?: string;
    reponseQ3?: string;
    surveyPrice?: surveyResultPriceType;
    surveyPriceRemiseCode? : string;

    showReglementModal: boolean;

}

const initialState: espressoJeuData = {
    signUpResult: undefined,
    reponseQ1: undefined,
    reponseQ2: undefined,
    reponseQ3: undefined,
    surveyPrice: undefined,
    surveyPriceRemiseCode: undefined,
    showReglementModal: false
}

export const setSignUpResult = createAction<ISignUpResult>('espressoJeuReducer/ISignUpResult')
export const setUserTel = createAction<string>('espressoJeuReducer/setUserTel')
export const setUserFirstName = createAction<string>('espressoJeuReducer/setUserFirstName')
export const setUserLastName = createAction<string>('espressoJeuReducer/setUserLastName')

export const setReponseQ1 = createAction<string>('espressoJeuReducer/setReponseQ1')
export const setReponseQ2 = createAction<string>('espressoJeuReducer/setReponseQ2')
export const setReponseQ3 = createAction<string>('espressoJeuReducer/setReponseQ3')

export const setResetQuestions = createAction('espressoJeuReducer/setResetQuestions')

export const setSurveyPrice = createAction<surveyResultPriceType>('espressoJeuReducer/setSurveyPrice')
export const setSurveyPriceRemiseCode = createAction<string>('espressoJeuReducer/setSurveyPriceRemiseCode')

export const setShowReglementModal = createAction<boolean>('espressoJeuReducer/setShowReglementModal')

const espressoJeuReducer = createReducer<espressoJeuData>(initialState, (builder) => {
    builder.addCase(setSignUpResult, (state, action) => { state.signUpResult = action.payload })

        .addCase(setReponseQ1, (state, action) => { state.reponseQ1 = action.payload })
        .addCase(setReponseQ2, (state, action) => { state.reponseQ2 = action.payload })
        .addCase(setReponseQ3, (state, action) => { state.reponseQ3 = action.payload })

        .addCase(setSurveyPrice, (state, action) => { state.surveyPrice = action.payload })
        .addCase(setSurveyPriceRemiseCode,  (state, action) => { state.surveyPriceRemiseCode = action.payload })
        .addCase(setResetQuestions, (state, action) => { 
            state.reponseQ1 = undefined;
            state.reponseQ2 = undefined;
            state.reponseQ3 = undefined;
            state.surveyPrice = undefined;
            state.surveyPriceRemiseCode = undefined;
        })

        .addCase(setShowReglementModal, (state, action) => { state.showReglementModal = action.payload })

})

export default espressoJeuReducer
