import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import Header from 'components/Header';
import './SurveyResultatLost.scss';
import getQuizzResultat, { IQuizzResult } from 'resources/getQuizzResultat';
import { ICoffee } from 'resources/CoffeesList';
import Button from 'components/Button';
import { useAppSelector, useAppDispatch } from 'store/store';
import { setSurveyPrice, surveyResultPriceType } from 'store/reducers/espressoJeuReducer';

interface IParams {
    type: 'Perdu' | 'DejaJoue'
}


const SurveyResultatLost: React.FC<IParams> = ({type}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const simulatePrice = searchParams.get("simulatePrice");
    const user = useAppSelector(state => state.espressoJeu.signUpResult);
    const surveyResultPrice = useAppSelector(state => state.espressoJeu.surveyPrice);

    useLayoutEffect (() => {
        document.body.scrollTo(0,0)
    }, [])

    useEffect(() => {


        if(!user)
        {
            navigate(ROUTES.JEU_INSCRIPTION_ROUTE);
        }

        if(simulatePrice)
        {
            if(simulatePrice == "Perdu") dispatch(setSurveyPrice('Perdu'));
            else if (simulatePrice == "DejaJoue") dispatch(setSurveyPrice('DejaJoue'));
        }
        else
        {

            dispatch(setSurveyPrice(type));
        }
        

        if(!surveyResultPrice)
        {
            //todo décommenter
            //navigate(ROUTES.INSCRIPTION_ROUTE)
        }
    }, [])


    let description = <></>;

    switch (surveyResultPrice) {
        case 'Perdu':
            description = <>
                <div>{"Malheureusement, vous n'avez pas gagné"}</div>
            </>
            break;
        case 'DejaJoue':
            description = <>
                    <div>Vous avez déjà joué. <br/>Merci une nouvelle fois pour votre participation !</div>
                </>
            break;
        default:
            break;
    }


    return <>
         <div id="resultat-lose-page" style={{ position: 'relative', width: '100%', height:'100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            
            <div id="panel-header">
                <Header />
            </div>
            <Container id='container'>
                <div id="panel-result" className='bg-white pt-3 pb-3 text-center'>
                    <div id="description" className='mt-4 fs-3'>
                        <b>{description}</b>
                    </div>
                </div>
                <div id="footer-win">
                    <Row>
                        <Col className='mt-2'>
                            <div>Rendez-vous sur notre site dès maintenant pour découvrir</div>
                            <div>toutes les actualités Nespresso et les offres du moment.</div>
                        </Col>
                        <Col xs="12" sm="12" md="4" className='mt-2'><Button className="win-btn w-100" onClick={() => window.open('https://www.nespresso.com','_blank')} >www.nespresso.com</Button></Col>
                    </Row>
                </div>  
            </Container>
            
        </div>
    </>
}

export default SurveyResultatLost