import React, { useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Question, { IQuestionData, IResponse } from 'components/Question';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { setReponseQ1 } from 'store/reducers/espressoJeuReducer';
import { useAppDispatch } from 'store/store';
import useUrlType from 'hooks/useUrlType';


export enum Q1Responses {
    TarteCitron = 'Tarte Citron',
    FlanPatissier = 'Flan patissier',
    FondantChocolat = 'Fondant chocolat'
}


const Question1: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const urlType = useUrlType();

    useLayoutEffect (() => {
        document.body.scrollTo(0,0)
    }, [])


    const onSelectResponse = (id: string) =>{
        dispatch(setReponseQ1(id));
        navigate(urlType == "jeu" ? ROUTES.JEU_QUESTION2_ROUTE: ROUTES.SELECTOR_QUESTION2_ROUTE)
    }

    return <>
        <Question 
            onSelectResponse={onSelectResponse}
            currentIndexQuestion={1}
            totalQuestion={3}
            popinTitle='Nespresso, un savoir-faire unique pour un café de haute qualité'
            popinContent={<Container>
                <Row>
                    <Col sm="12" md="12" lg="6">
                        <div><h2 className='text-uppercase'><b>Nespresso, un savoir faire hors du commun</b></h2></div>
                        <div>De la sélection soigneuse du café, en passant par une torréfaction et une mouture sur-mesure pour chacune des origines, notre savoir-faire permet de vous offrir un café profondément ancré dans son terroir, respectant parfaitement le profil des cafés</div>
                        <div className='mt-4'><img src="/img/visuel_pop_1_1.jpg" style={{width:"100%", height:"450px", objectFit:"cover"}}></img></div>
                    </Col>
                    <Col sm="12" md="12" lg="6">
                        <div className='d-none d-lg-block'><img src="/img/visuel_pop_1_2.jpg" style={{maxWidth:"100%"}}></img></div>
                        <div className='mt-4'><h5 className='text-uppercase'><b>Le secret de notre torréfaction</b></h5></div>
                        <div className='mt-4'>
                            <div>Chez Nespresso, nous faisons le choix de vous offrir <b>une qualité en tasse sans compromis</b>. Pour parvenir à ce résultat, nos maîtres torréfacteurs torréfient les grains de café de façon minutieuse et délicate en chauffant le café vert selon les <b>spécificités</b> et le <b>résultat en tasse</b> souhaité.</div>
                            <div className='mt-4'>Ainsi, dans le plus grand respect de la matière première utilisée, nous faisons <b>varier {"l'intensité"} de la torréfaction et sa durée</b> pour révéler les essences aromatiques les plus subtiles et libérer les saveurs de chaque origine de nos cafés.</div>
                        </div>
                        <div className='mt-4'><h5 className='text-uppercase'><b>Le split-roasting</b></h5></div>
                        <div>La <b>parfaite connaisance des caractéristiques de nos grains</b> par nos maîtres torréfacteurs nous permet de pratiquer une technique rare mais totalement maîtrisée: le split-roasting. Cette méthode consiste à torréfier deux types de cafés différents séparément avant de les mélanger pour vous proposer <b>une tasse à la personnalité complexe, équilibrée et unique</b>.</div>
                    </Col>
                </Row>
            </Container>}
            data={{
                question:"Quel est votre dessert préféré ?",
                responses:[
                    {id:Q1Responses.TarteCitron, image:"/img/Q1_1.jpg",  title:"Une tarte au citron", subTible:"Pour ses notes acidulées"} as IResponse,
                    {id:Q1Responses.FlanPatissier, image:"/img/Q1_2.jpg",  title:"Un flan pâtissier", subTible:"Pour ses notes de beurre"} as IResponse,
                    {id:Q1Responses.FondantChocolat, image:"/img/Q1_3.jpg",  title:"Un fondant au chocolat noir", subTible:"Pour ses notes intenses"} as IResponse
                ],
                
        } as IQuestionData}/>
    </>
}

export default Question1