

export interface ICoffee{
    type: "original" | "vertuo";
    name: string;
    subtitle1: string;
    subtitle2: string;
    intensity?: number;
    size: "Expresso" | "Double Expresso" | "Expresso - Lungo" | "Lungo" | "Mug" | "Recette" | "Ristretto - Expresso" | "Gran Lungo" | "Mug";
    image: string;
    url:string;
}

export const CoffeesList:ICoffee[] = [
    // original
    {type: "original", name:"Cosi", url:"https://www.nespresso.com/fr/fr/order/capsules/original/cosi-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900733800478/Cosi-OL.png?impolicy=product&imwidth=90", intensity: 13, size: "Expresso", subtitle1:"Le plus intense.", subtitle2: "Notes de cacao amer."} as ICoffee,
    {type: "original", name:"Colombia", url:"https://www.nespresso.com/fr/fr/order/capsules/original/colombia-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901253664798/Colombia-OL.png?impolicy=product&imwidth=90", intensity: 6, size: "Expresso - Lungo", subtitle1:"Vif et maturé aux notes de fruits.", subtitle2: "Récolte tardive."} as ICoffee,
    {type: "original", name:"Peru Organic", url:"https://www.nespresso.com/fr/fr/order/capsules/original/original-peru-organic", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901248127006/Peru-Organic-OL.png?impolicy=product&imwidth=90", intensity: 6, size: "Expresso - Lungo", subtitle1:"Café bio aux notes de fruits", subtitle2: "et de céréales grillées."} as ICoffee,
    {type: "original", name:"Chiaro", url:"https://www.nespresso.com/fr/fr/order/capsules/original/barista-chiaro-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901579444254/Chiaro-OL.png?impolicy=product&imwidth=90", intensity: undefined, size: "Recette", subtitle1:"Pour des recettes ontueuses à base de lait.", subtitle2: "Notes caramel et biscuit."} as ICoffee,
    {type: "original", name:"Ethiopia", url:"https://www.nespresso.com/fr/fr/order/capsules/original/ethiopia-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901254254622/Ethiopia-OL.png?impolicy=product&imwidth=90", intensity: 4, size: "Expresso - Lungo", subtitle1:"Doux aux notes fleuries. Séché en plein air.", subtitle2: "Séché en plein air. Origine : Ethiopie."} as ICoffee,
    {type: "original", name:"Volluto", url:"https://www.nespresso.com/fr/fr/order/capsules/original/volluto-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900731965470/Volluto-OL.png?impolicy=product&imwidth=90", intensity: 4, size: "Expresso", subtitle1:"Un café doux et harmonieux.", subtitle2: "Notes de céréales biscuitées."} as ICoffee,
    {type: "original", name:"Tokyo Lungo", url:"https://www.nespresso.com/fr/fr/order/capsules/original/tokyo-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900945711134/Tokyo-Vivalto-Lungo-OL.png?impolicy=product&imwidth=90", intensity: 6, size: "Lungo", subtitle1:"Café hommage au raffinement japonais.", subtitle2: "Notes fleuries délicates."} as ICoffee,
    {type: "original", name:"Shanghai Lungo", url:"https://www.nespresso.com/fr/fr/order/capsules/original/shanghai-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900940042270/Shanghai-Lungo-OL.png?impolicy=product&imwidth=90", intensity: 5, size: "Lungo", subtitle1:"Typique du café bu en Chine.", subtitle2: "Notes fruitées de citron et baies rouges."} as ICoffee,
    {type: "original", name:"Nicaragua", url:"https://www.nespresso.com/fr/fr/order/capsules/original/nicaragua-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901254058014/Nicaragua-OL.png?impolicy=product&imwidth=90", intensity: 5, size: "Expresso - Lungo", subtitle1:"Un Arabica-nectar aux notes de céréales sucrées.", subtitle2: "Origine : Nicaragua."} as ICoffee,
    {type: "original", name:"Paris Espresso", url:"https://www.nespresso.com/fr/fr/order/capsules/original/parisespresso-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900937453598/Paris-Espresso-OL.png?impolicy=product&imwidth=90", intensity: 6, size: "Expresso", subtitle1:"Typique des terrasses parisiennes.", subtitle2: "Equilibré avec une note d'acidité."} as ICoffee,
    {type: "original", name:"Livanto", url:"https://www.nespresso.com/fr/fr/order/capsules/original/livanto-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900925362206/Genova-Livanto-OL.png?impolicy=product&imwidth=90", intensity: 6, size: "Expresso", subtitle1:"Un café rond.", subtitle2: "Notes de céréales grillées et de caramel."} as ICoffee,
    {type: "original", name:"Scuro", url:"https://www.nespresso.com/fr/fr/order/capsules/original/barista-scuro-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901580296222/Scuro-OL.png?impolicy=product&imwidth=90", intensity: undefined, size: "Recette", subtitle1:"Pour des recettes intenses à base de lait.", subtitle2: "Notes grillées de céréales."} as ICoffee,
    {type: "original", name:"Vienna Lungo", url:"https://www.nespresso.com/fr/fr/order/capsules/original/vienna-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900944597022/Vienna-Linizio-Lungo-OL.png?impolicy=product&imwidth=90", intensity: 6, size: "Lungo", subtitle1:"Equilibré, idéal pour un café viennois typique.", subtitle2: "Notes de céréales."} as ICoffee,
    {type: "original", name:"Buenos Aires Lungo", url:"https://www.nespresso.com/fr/fr/order/capsules/original/buenos-aires-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900940795934/Buenos-Aires-Lungo-OL.png?impolicy=product&imwidth=90", intensity: 4, size: "Lungo", subtitle1:"Café doux comme l'aiment les Argentins.", subtitle2: "Notes de céréales et de noix."} as ICoffee,
    {type: "original", name:"Café Joyeux", url:"https://www.nespresso.com/fr/fr/order/capsules/original/cafe-joyeux",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15906666381342/Cafe-Joyeux-OL-2.png?impolicy=product&imwidth=90", intensity: 5, size: "Expresso - Lungo", subtitle1:"Café équilibré, création exclusive.", subtitle2: "Notes douces de céréales toastées."} as ICoffee,
    {type: "original", name:"Capriccio", url:"https://www.nespresso.com/fr/fr/order/capsules/original/capriccio-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900731801630/Capriccio-OL.png?impolicy=product&imwidth=90", intensity: 5, size: "Expresso", subtitle1:"Un café affirmé, avec une fine acidité.", subtitle2: "Notes de céréales."} as ICoffee,
    {type: "original", name:"Indonesia", url:"https://www.nespresso.com/fr/fr/order/capsules/original/indonesia-organic-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901252288542/Indonessia-OL.png?impolicy=product&imwidth=90", intensity: 8, size: "Expresso - Lungo", subtitle1:"Café bio aux notes boisées et de tabac.", subtitle2: "Origine : Indonésie."} as ICoffee,
    {type: "original", name:"India", url:"https://www.nespresso.com/fr/fr/order/capsules/original/india-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901253468190/India-OL.png?impolicy=product&imwidth=90", intensity: 11, size: "Expresso - Lungo", subtitle1:"Robusta moussonné, aux notes boisées et épicées.", subtitle2: "Origine :  Inde."} as ICoffee,
    {type: "original", name:"Kahawa Ya Congo", url:"https://www.nespresso.com/fr/fr/order/capsules/original/kahawa-ya-congo-organic-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901578362910/Kahawa-ya-Congo-OL.png?impolicy=product&imwidth=90", intensity: 7, size: "Expresso - Lungo", subtitle1:"Café biologique doux et fruité.", subtitle2: "Notes de céréales grillées et noix. Origine : Congo."} as ICoffee,
    {type: "original", name:"Corto", url:"https://www.nespresso.com/fr/fr/order/capsules/original/barista-corto-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15988447379486/MicrosoftTeams-image-17-.png?impolicy=product&imwidth=90", intensity: undefined, size: "Recette", subtitle1:"Pour des recettes intenses à base de lait.", subtitle2: "Notes d'épices poivrées."} as ICoffee,
    {type: "original", name:"Roma", url:"https://www.nespresso.com/fr/fr/order/capsules/original/roma-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900752019486/Roma-OL.png?impolicy=product&imwidth=90", intensity: 8, size: "Ristretto - Expresso", subtitle1:"Equilibre subtil entre puissance et finesse des arômes.", subtitle2: "Notes boisées."} as ICoffee,
    {type: "original", name:"Ristretto", url:"https://www.nespresso.com/fr/fr/order/capsules/original/ristretto-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900743565342/Ristretto-Italiano-OL.png?impolicy=product&imwidth=90", intensity: 10, size: "Ristretto - Expresso", subtitle1:"Un café puissant et contrasté.", subtitle2: "Notes torréfiées et fruitées."} as ICoffee,
    {type: "original", name:"Arpeggio", url:"https://www.nespresso.com/fr/fr/order/capsules/original/arpeggio-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900750020638/Firenze-Arpeggio-OL.png?impolicy=product&imwidth=90", intensity: 9, size: "Ristretto - Expresso", subtitle1:"Un café dense et crémeux.", subtitle2: "Notes aromatiques de cacao poudré."} as ICoffee,
    {type: "original", name:"Napoli", url:"https://www.nespresso.com/fr/fr/order/capsules/original/napoli-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/16452983881758/Napoli-OL.png?impolicy=product&imwidth=90", intensity: 13, size: "Ristretto - Expresso", subtitle1:"Le plus intense.", subtitle2: "Notes de cacao amer."} as ICoffee,
    {type: "original", name:"Venezia", url:"https://www.nespresso.com/fr/fr/order/capsules/original/venezia-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/26484705493022/Venezia-OL.png?impolicy=product&imwidth=90", intensity: 8, size: "Ristretto - Expresso", subtitle1:"Un café rond et délicat.", subtitle2: "Notes de céréales grillées et de caramel."} as ICoffee,
    {type: "original", name:"Rio", url:"https://www.nespresso.com/fr/fr/order/capsules/original/riodejaneiro-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900937682974/Rio-De-Janeiro-OL.png?impolicy=product&imwidth=90", intensity: 9, size: "Expresso", subtitle1:"Emblématique du petit café brésilien.", subtitle2: "Légèrement amer, notes d'épices."} as ICoffee,
    {type: "original", name:"Istanbul", url:"https://www.nespresso.com/fr/fr/order/capsules/original/istanbul-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900938403870/Istambul-Espresso-OL.png?impolicy=product&imwidth=90", intensity: 8, size: "Ristretto - Expresso", subtitle1:"Café hommage au célèbre café turc.", subtitle2: "Intense et velouté, notes d'épices."} as ICoffee,
    {type: "original", name:"Stockholm", url:"https://www.nespresso.com/fr/fr/order/capsules/original/stockholm-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15900943220766/Stockholm-Lungo-OL.png?impolicy=product&imwidth=90", intensity: 8, size: "Lungo", subtitle1:"Hommage à la fika, la pause café suédoise.", subtitle2: "Notes maltées et grillées ."} as ICoffee,

    // vertuo
    {type:"vertuo", name:"Toccanto", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/toccanto-capsule-cafe",  image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901810491422/Toccanto-VL.png?impolicy=product&imwidth=140", intensity: 5, size: "Expresso", subtitle1:"Espresso délicat et équilibré.", subtitle2: "Notes de fruits confits et pain grillé."} as ICoffee,
    {type:"vertuo", name:"Voltesso", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/voltesso-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901817864222/Voltesso-VL.png?impolicy=product&imwidth=140", intensity: 4, size: "Expresso", subtitle1:"Espresso équilibré et gourmand.", subtitle2: "Notes biscuitées."} as ICoffee,
    {type:"vertuo", name:"Peru Organic", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/vertuo-peru-organic", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16071298613278/Peru-Organic-VL.png?impolicy=product&imwidth=140", intensity: 6, size: "Expresso", subtitle1:"Espresso bio aux notes de fruits et de céréales.", subtitle2: "Origine : Pérou."} as ICoffee,
    {type:"vertuo", name:"Bianco Piccolo", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/barista-bianco-piccolo-caspule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16060014395422/ResponsiveStandard-7230.80.png?impolicy=product&imwidth=140", intensity: undefined, size: "Recette", subtitle1:"Idéal pour les amateurs de cappuccino.", subtitle2: ""} as ICoffee,
    {type:"vertuo", name:"Bianco Doppio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/barista-bianco-doppio-caspule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16672194625566/Bianco-Doppio-VL.png?impolicy=product&imwidth=140", intensity: undefined, size: "Recette", subtitle1:"Un café onctueux aux notes caramélisées.", subtitle2: ""} as ICoffee,
    {type:"vertuo", name:"Bianco Forte", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/barista-bianco-forte-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16072259993630/Packshot-panier-Desktop-2000x2000.png?impolicy=product&imwidth=140", intensity: undefined, size: "Recette", subtitle1:"Pour des recettes intenses à base de lait.", subtitle2: "Notes grillées de céréales."} as ICoffee,
    {type:"vertuo", name:"Ethiopia", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/vertuo-ethiopia-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901598187550/ETHIOPIA-VL.png?impolicy=product&imwidth=140", intensity: 4, size: "Gran Lungo", subtitle1:"Café doux et fleuri, notes de myrtille  et musc.", subtitle2: "Origine : Ethiopie."} as ICoffee,
    {type:"vertuo", name:"Colombia", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/colombia-vertuo-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901601693726/COLOMBIA-VL.png?impolicy=product&imwidth=140", intensity: 5, size: "Mug", subtitle1:"Café à fermentation longue, notes fruitées.", subtitle2: "Origine : Colombie."} as ICoffee,
    {type:"vertuo", name:"Solelio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/solelio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901998317598/SOLELIO-VL.png?impolicy=product&imwidth=140", intensity: 2, size: "Mug", subtitle1:"Café fruité très doux.", subtitle2: "Notes de fruits rouges et céréales grillées."} as ICoffee,
    {type:"vertuo", name:"Kahawa ya Congo",url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/vertuo-kahawa-ya-congo-organic-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/17565079830558/Kahawa-ya-congo-VL.png?impolicy=product&imwidth=140", intensity: 6, size: "Mug", subtitle1:"Un café bio de l'est du Congo aux notes de fruits et de céréales.", subtitle2: ""} as ICoffee,
    {type:"vertuo", name:"Inizio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/inizio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901825892382/Inizio-VL.png?impolicy=product&imwidth=140", intensity: 4, size: "Gran Lungo", subtitle1:"Un café équilibré légèrement acide.", subtitle2: "Notes de céréales et de fleurs."} as ICoffee,
    {type:"vertuo", name:"Dolce", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/dolce-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901818257438/Dolce-VL.png?impolicy=product&imwidth=140", intensity: 5, size: "Double Expresso", subtitle1:"Un café doux et onctueux.", subtitle2: "Notes maltées et de céréales."} as ICoffee,
    {type:"vertuo", name:"Orafio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/orafio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16071299334174/Orafio-VL.png?impolicy=product&imwidth=140", intensity: 6, size: "Expresso", subtitle1:"Espresso complexe subtilement amer.", subtitle2: "Notes de céréales et de caramel."} as ICoffee,
    {type:"vertuo", name:"Costa Rica", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/vertuo-costa-rica-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901603725342/Costa-Rica-VL.png?impolicy=product&imwidth=140", intensity: 7, size: "Gran Lungo", subtitle1:"Café délicat aux notes de céréales maltées.", subtitle2: "Origine : Costa Rica."} as ICoffee,
    {type:"vertuo", name:"Melozio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/melozio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16990314823710/Melozio-VL.png?impolicy=product&imwidth=140", intensity: 6, size: "Mug", subtitle1:"Un café onctueux et équilibré.", subtitle2: "Notes de céréales et de miel."} as ICoffee,
    {type:"vertuo", name:"Arondio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/arondio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901825073182/ARONDIO-VL.png?impolicy=product&imwidth=140", intensity: 6, size: "Gran Lungo", subtitle1:"Un café riche et doux.", subtitle2: "Notes de céréales."} as ICoffee,
    {type:"vertuo", name:"Scuro", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/scuro-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901820223518/Scuro-VL.png?impolicy=product&imwidth=140", intensity: 11, size: "Double Expresso", subtitle1:"Un café intense à la texture épaisse.", subtitle2: "Notes fumées et de cacao."} as ICoffee,
    {type:"vertuo", name:"Altissio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/altissio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901816946718/Altissio-VL.png?impolicy=product&imwidth=140", intensity: 9, size: "Expresso", subtitle1:"Espresso corsé et crémeux.", subtitle2: "Notes de céréales."} as ICoffee,
    {type:"vertuo", name:"Diavolitto", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/diavolitto-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901813997598/DIAVOLITTO-VL.png?impolicy=product&imwidth=140", intensity: 11, size: "Expresso", subtitle1:"Espresso puissant et velouté.", subtitle2: "Notes de bois, de chêne et de cuir."} as ICoffee,
    {type:"vertuo", name:"Mexico", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/mexico-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901602578462/Mexico-VL.png?impolicy=product&imwidth=140", intensity: 7, size: "Mug", subtitle1:"Robusta intense lavé deux fois, grillé et épicé.", subtitle2: "Origine : Mexique."} as ICoffee,
    {type:"vertuo", name:"Fortado", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/fortado-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901821173790/FORTADO-VL.png?impolicy=product&imwidth=140", intensity: 8, size: "Gran Lungo", subtitle1:"Un café intense et corsé.", subtitle2: "Notes de cacao et de bois de chêne."} as ICoffee,
    {type:"vertuo", name:"Odacio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/odacio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901830545438/Odacio-VL.png?impolicy=product&imwidth=140", intensity: 7, size: "Mug", subtitle1:"La version décaféinée de Melozio.", subtitle2: "Notes de céréales et de miel."} as ICoffee,
    {type:"vertuo", name:"Il Caffé", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/vertuo-il-caffe-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/16071303331870/IL-Caffe-VL.png?impolicy=product&imwidth=140", intensity: 11, size: "Expresso", subtitle1:"Espresso intense et complexe.", subtitle2: "Notes de torréfiées et de céréales."} as ICoffee,
    {type:"vertuo", name:"Chiaro", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/chiaro-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901820452894/Chiaro-VL.png?impolicy=product&imwidth=140", intensity: 8, size: "Double Expresso", subtitle1:"Un café intense et savoureux.", subtitle2: "Notes boisées."} as ICoffee,
    {type:"vertuo", name:"Stormio", url:"https://www.nespresso.com/fr/fr/order/capsules/vertuo/stormio-capsule-cafe", image:"https://www.nespresso.com/ecom/medias/sys_master/public/15901827629086/Stormio-VL.png?impolicy=product&imwidth=140", intensity: 8, size: "Mug", subtitle1:"Un café intense et corsé.", subtitle2: "Notes de céréales épicées et boisées."} as ICoffee
];




export default function GetCoffee(name: string, type: 'original' | 'vertuo') {
    const found = CoffeesList.find(m => m.name.toLocaleLowerCase() == name.toLocaleLowerCase() && m.type.toLocaleLowerCase() == type.toLocaleLowerCase());
    if(!found) console.error("Coffee not found", name, type)
    return found;
}