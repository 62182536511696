import React, { useState }  from 'react';
import "./Footer.scss";
import PopIn from './PopIn';
import Reglement from './Reglement';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setShowReglementModal } from 'store/reducers/espressoJeuReducer';
import MentionsLegales from './MentionsLegales';
import useUrlType from 'hooks/useUrlType';

const Footer: React.FC = () => {
    const dispatch = useAppDispatch();
    const urlType = useUrlType();
    
    // const [showReglementModal, setShowReglementModal] = useState(false);
    const showReglementModal = useAppSelector(state => state.espressoJeu.showReglementModal);

    
    const [showMentionLegalModal, setShowMentionLegalModal] = useState(false);


    return (<>
    <div id='footer'>
        <div className='d-flex justify-content-center'>
            {urlType == "jeu" && <div className='clickable' onClick={() => dispatch(setShowReglementModal(true))}>Réglement</div>}
            {urlType == "jeu" && <div className='clickable' onClick={() => setShowMentionLegalModal(true)}>Mentions légales</div>}
            <div className='clickable' onClick={() => window.open('https://www.nespresso.com/fr/fr/cookies','_blank')}>Cookies</div>
        </div>
    </div>
        <PopIn show={showReglementModal} size='xl' fullscreen onHide={() => dispatch(setShowReglementModal(false))}> <Reglement /></PopIn>
        <PopIn show={showMentionLegalModal} size='lg' fullscreen onHide={() => setShowMentionLegalModal(false)}><MentionsLegales /></PopIn>
    </>);
}

export default Footer
