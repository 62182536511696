import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import Header from 'components/Header'
import { Col, Container, Row } from 'react-bootstrap'
import "./NotFoundPage.scss";
import Button from 'components/Button'
const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()

    const redirectToHomePage = () => {
        navigate(ROUTES.JEU_INSCRIPTION_ROUTE)
    }

    return (<>
        <div id="notfound-page" style={{ position: 'relative', width: '100%', height:'100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
           
           <div id="panel-header">
               <Header />
           </div>
           <Container id='container'>
               <div id="panel-result" className='bg-white pt-5 pb-5 text-center'>
                   <div id="description" className='mt-4 fs-3'>
                        <div><b>Oups</b></div>
                       <div>{"La page demandée n'existe pas"}</div>
                   </div>
               </div>
               <div id="footer-win">
                   <Row>
                       <Col xs="12" className='mt-2'><Button className="win-btn w-100" onClick={redirectToHomePage} >{"Retourner sur la page d'acceuil"}</Button></Col>
                   </Row>
               </div>  
           </Container>
           
       </div>
   </>)
}

export default NotFoundPage
