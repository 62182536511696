import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Question, { IQuestionData, IResponse } from 'components/Question';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import Header from 'components/Header';
import './SurveyResultat.scss';
import getQuizzResultat, { IQuizzResult } from 'resources/getQuizzResultat';
import { ICoffee } from 'resources/CoffeesList';
import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getFirebase } from 'firebase_helper';
import IGameResult from 'models/IGameResult';
import { setSurveyPrice, setSurveyPriceRemiseCode } from 'store/reducers/espressoJeuReducer';
import useUrlType from 'hooks/useUrlType';

const SurveyResultat: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const urlType = useUrlType();

    const [loading, setLoading] = useState(false);
    const [profilType, setProfilType] = useState("doux");
    const [coffeesResult, setCoffeesResult] = useState<IQuizzResult>();

    const [searchParams] = useSearchParams();
    const showAll = searchParams.get("showAll");

    const responseQ1 = useAppSelector(state => state.espressoJeu.reponseQ1);
    const responseQ2 = useAppSelector(state => state.espressoJeu.reponseQ2);
    const responseQ3 = useAppSelector(state => state.espressoJeu.reponseQ3);

    const user = useAppSelector(state => state.espressoJeu.signUpResult);

    const surveyResultPrice = useAppSelector(state => state.espressoJeu.surveyPrice);
    const surveyResultPriceRemiseCode = useAppSelector(state => state.espressoJeu.surveyPriceRemiseCode);

    


    useLayoutEffect (() => {
        document.body.scrollTo(0,0)
    }, [])

    useEffect(() => {

        if(surveyResultPriceRemiseCode && surveyResultPrice)
        {
            processresult(surveyResultPrice, surveyResultPriceRemiseCode);
        }



        if(!showAll && urlType == "jeu" && !user)
        {
            navigate(ROUTES.JEU_INSCRIPTION_ROUTE);
        }


        if(showAll)
        {
            setCoffeesResult(getQuizzResultat('','',''));
        }
        else{
            if(!responseQ1) navigate(urlType == "jeu" ? ROUTES.JEU_QUESTION1_ROUTE : ROUTES.SELECTOR_QUESTION1_ROUTE);
            else if(!responseQ2) navigate(urlType == "jeu" ? ROUTES.JEU_QUESTION2_ROUTE: ROUTES.SELECTOR_QUESTION2_ROUTE);
            else if(!responseQ3) navigate(urlType == "jeu" ? ROUTES.JEU_QUESTION3_ROUTE : ROUTES.SELECTOR_QUESTION3_ROUTE);
        }



        if(!showAll && responseQ1 && responseQ2 && responseQ3)
        {
            const coffees = getQuizzResultat(responseQ1, responseQ2, responseQ3);
            
            setCoffeesResult(coffees);
        }

    }, [responseQ1, responseQ2, responseQ3])


    const checkResult = async ()=> {
        if(user)
        {
            const fireBaseBackend = getFirebase();
            setLoading(true);
            const result = await fireBaseBackend.checkResult(user.email, responseQ1 ?? "", responseQ2?? "", responseQ3?? "")
            .finally(() => {
                setLoading(false);
            })

            const gameResult = result.data as IGameResult;
            console.log("gameResult", gameResult)
            processresult(gameResult.result, gameResult.remiseCode);
            
        }
      
    }

    const processresult =(result: undefined | '200Capsules' | '50Capsules' | '5eRemise' | 'Perdu' | 'DejaJoue', remiseCode: string | undefined) => {
        if(result){
            dispatch(setSurveyPrice(result));

            switch (result) {
                case 'Perdu':
                    navigate(ROUTES.JEU_GAME_LOST_ROUTE);
                    break;
                case 'DejaJoue':
                    navigate(ROUTES.JEU_GAME_ALREADY_PLAYED_ROUTE);
                    break;
                case '5eRemise':
                    //todo set remise code
                    if(remiseCode) {
                        dispatch(setSurveyPriceRemiseCode(remiseCode))
                        navigate(ROUTES.JEU_GAME_WIN_ROUTE);
                    }
                    else{
                        // anormel si on arrive ici !
                        navigate(ROUTES.JEU_GAME_LOST_ROUTE);
                    }
                    break;
                default:
                    navigate(ROUTES.JEU_GAME_WIN_ROUTE);
                    break;
            }

        }
        else{
            navigate(ROUTES.JEU_INSCRIPTION_ROUTE);
        }
    }


    const renderCoffee =(coffee: ICoffee, imageClass: string) => {

        const onImgClick = (url: string) => {
            if(url) window.open(url,'_blank')
        }


        return  <div className={'result-item ' + imageClass}>
                    <div className={'header-img ' + imageClass} onClick={() => onImgClick(coffee.url)} style={{backgroundImage:`url(${coffee.image})`}}></div>
                    <div className='mt-3'>
                        <h5 className='text-uppercase'><b>{coffee.name}</b></h5>
                        <div>{coffee.subtitle1}</div>
                        <div>{coffee.subtitle2}</div>
                    </div>
                    <Row className='justify-content-between mt-3'>
                        <Col className='text-center'><span>Intensité {coffee.intensity}</span></Col>
                        <Col className='text-center'><span>{coffee.size}</span></Col>
                    </Row>
                </div>;
    }

    return <>
         <div id="resultat-page" style={{ position: 'relative', width: '100%', height:'100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            
            <div id="panel-header">
                <Header />
            </div>
            <Container id='container' style={{maxWidth:"1100px"}}>
                <div id="title" className='text-center text-white fs-4'>
                    <div>Merci pour vos réponses. </div> 
                    <div>Pour vos dégustations, <b>Nespresso vous recommande des cafés au profil</b> <span id="profil-type"><b>{profilType}</b></span></div>
                    <div>Découvrez votre sélection personnalisée :</div>
                    </div>
                <div id="panel-result" className='bg-white pt-3 pb-5 text-center'>

                    {/* GAMME ORIGINAL */}
                    <h4 className='mt-3'><b>La gamme Original</b></h4>
                    <div>Expérimentez un authentique Espresso ou un véritable Lungo aux arômes uniques et savoureux.</div>
                    <div>Le plaisir {"d'un"} café de haute qualité durable.</div>
                    <Row className='mt-3 justify-content-center '>
                        {coffeesResult && coffeesResult.original.length > 0 && coffeesResult.original.map((coffee, i) => {
                            return  <Col key={i} className='mb-4'>{renderCoffee(coffee, "original")}</Col>;
                        })}
                    </Row>


                    {/* GAMME VERTUO */}
                    <h4 className='mt-3'><b>La gamme Vertuo</b></h4>
                    <div>Découvrez une expérience café exceptionnelle en 4 tailles de tasse :</div>
                    <div>de {"l'Espresso"} intense et unique au Mug à {"l'arôme"} savoureux et gourmand.</div>
                    <Row className='mt-3 justify-content-center'>
                        {coffeesResult && coffeesResult.vertuo.length > 0 && coffeesResult.vertuo.map((coffee, i) => {
                            return  <Col key={i} className='mb-4'>{renderCoffee(coffee, "vertuo")}</Col>;
                        })}
                    </Row>


                    {urlType == "jeu" && <div id="win-panel" className='mt-3'>
                        <Row className='align-items-center g-0'>
                            <Col className='row-cols-auto d-none d-md-block' xs="5" sm="5" md="4"><div className='win-image'></div></Col>
                            <Col>
                                <div className='win-text fs-4'>Nespresso vous accompagne dans<br/> vos premières dégustations de café. <b>A vous de jouer !</b></div>
                                <div className='mt-3 fs-3'><Button loading={loading} className="win-btn" onClick={() => checkResult()} ><b>Avez-vous gagné ?</b></Button></div>
                            </Col>
                        </Row>
                    </div>}

                    {urlType == "selector" && <div id="win-panel" className='mt-3'>
                        <Row className='align-items-center g-0'>
                            <Col className='row-cols-auto d-none d-md-block' xs="5" sm="5" md="4"><div className='win-image'></div></Col>
                            <Col>
                                <div className='win-text fs-4'>Nespresso vous accompagne dans<br/> vos premières dégustations de café.</div>
                                <div className='mt-3 fs-3'><Button loading={loading} className="win-btn" onClick={() => window.location.href ='https://www.nespresso.com/fr/fr/order/capsules/original/'} ><b>{"J'en profite"}</b></Button></div>
                            </Col>
                        </Row>
                    </div>}
                    
                    <div className='mt-4 mb-5'>
                        <div>Retrouvez dès maintenant les cafés de votre sélection personnalisée,</div>
                        <div>et toutes les actualités Nespresso sur notre site et dans les Boutiques Nespresso.</div>
                        <div className='mt-4'><Button className='nespresso-link' onClick={() => window.open('https://www.nespresso.com','_blank')} >www.nespresso.com</Button></div>
                    </div>
                </div>
            </Container>
            
        </div>
    </>
}

export default SurveyResultat