import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Question, { IQuestionData, IResponse } from 'components/Question';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { setReponseQ3 } from 'store/reducers/espressoJeuReducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import useUrlType from 'hooks/useUrlType';


export enum Q3Responses {
    BlackEspresso = 'Black Espresso',
    Lait = 'Lait',
    BlackLong = 'Black Long'
}


const Question3: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const responseQ2 = useAppSelector(state => state.espressoJeu.reponseQ2);
    const urlType = useUrlType();
    
    useLayoutEffect (() => {
        document.body.scrollTo(0,0)
    }, [])

    useEffect(() => {
        if(!responseQ2)
        {
            navigate(urlType == "selector" ? ROUTES.SELECTOR_QUESTION2_ROUTE : ROUTES.JEU_QUESTION2_ROUTE)
        }
    }, [])

    const onSelectResponse = (id: string) =>{
        dispatch(setReponseQ3(id));
        navigate(urlType == "selector" ? ROUTES.SELECTOR_RESULTAT_SURVEY_ROUTE : ROUTES.JEU_RESULTAT_SURVEY_ROUTE)
    }

    return <>
        <Question 
            onSelectResponse={onSelectResponse}
            currentIndexQuestion={3}
            totalQuestion={3}
            popinTitle='Nespresso est certifié B-CORP™'
            popinContent={<Container>
                <Row>
                    <Col sm="12" md="12" lg="6">
                    <div><h2 className='text-uppercase'><b>Nespresso, entreprise certifiée B-Corp&trade;</b></h2></div>
                        <div>Depuis 2022, Nespresso est certifié B-Corp&trade; au niveau mondial. Nespresso fait ainsi partie {"d'un"} mouvement mondial vers une économie plus inclusive plus équitable et plus régénératrice.</div>
                        <div className='mt-4'><img src="/img/visuel_pop_3_1.jpg" style={{width:"100%", height:"550px", objectFit:"cover"}}></img></div>
                    </Col>
                    <Col sm="12" md="12" lg="6">
                        <div className='d-none d-lg-block'><img src="/img/visuel_pop_3_2.jpg" style={{width:"100%", maxHeight:"250px", objectFit:"cover"}}></img></div>
                        <div className='mt-4'><h5 className='text-uppercase'><b>Heureux {"d'être"} B-Corp&trade;</b></h5></div>
                        <div className='mt-4'>Nespresso rejoint un mouvement {"d'entreprises"} qui respectent des normes sociales et environnementales élevées et {"s'inscrivent"} dans une démarche de progrès.</div>
                        <div className='mt-4'>Pour Nespresso, la certification mondiale B-Corp&trade; atteste de 30 années {"d'engagement"} et nous pousse à aller plus loin demain.</div>
                        <div className='mt-4'><h6><b>En faveur du climat</b></h6></div>
                        <div>Réduction de 24% des émissions de CO2 {"d'une"} tasse de café Nespresso entre 2009 et 2020</div>
                        <div className='mt-4'><h6><b>En faveur de nos caféiculteurs partenaires</b></h6></div>
                        <div>Accompagnement et formation à des pratiques agricoles plus durables en partenariat avec {"l'ONG"} Rainforest Alliance depuis 2003</div>
                        <div className='mt-4'><h6><b>En faveur du recyclage et du reconditionnement</b></h6></div>
                        <div>Lancement de capsultes en aluminium recyclé et vente de machines reconditionnées</div>
                    </Col>
                </Row>
            </Container>}
            data={{
                question:"En choisissant votre café, vous privilégiez...",
                responses:[
                    {id:Q3Responses.BlackEspresso, image:"/img/Q3_1.jpg",  title:"L'origine des cafés", subTible:"Nespresso source son café d'exception dans 18 pays pour la gamme permanente et avec le programme Reviving Origins"} as IResponse,
                    {id:Q3Responses.Lait, image:"/img/Q3_2.jpg",  title:"La vie des caféiculteurs", subTible:"Nespresso travaille avec plus de 400 agronomes qui accompagnent les caféiculteurs sur le terrain afin de contribuer à pérenniser la culture du café de haute qualité et à améliorer leurs conditions de vie"} as IResponse,
                    {id:Q3Responses.BlackLong, image:"/img/Q3_3.jpg",  title:"L'art de la torréfaction", subTible:"De la sélection scrupuleuse des grains de café à la torréfaction et la mouture sur mesure pour chacune des origines"} as IResponse
                ],
                
        } as IQuestionData}/>
    </>
}

export default Question3