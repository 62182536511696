import React, { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikHelpers } from 'formik';
import Button from 'components/Button';
import Header from 'components/Header';
import './Question.scss';
import PopIn from 'components/PopIn';
import { useAppSelector } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import useUrlType from 'hooks/useUrlType';


export interface IResponse{
    id: string;
    image: string;
    title: string;
    subTible: string;
}

export interface IQuestionData{
    question: string;
    responses: IResponse[];
}


interface OwnProps{
    data: IQuestionData;
    totalQuestion: number;
    currentIndexQuestion: number;
    popinTitle: string;
    popinContent: JSX.Element;
    onSelectResponse: (id: string) => void;
}

const Question: React.FC<OwnProps> = ({data, totalQuestion, currentIndexQuestion, popinTitle, popinContent, onSelectResponse}) => {
    const navigate = useNavigate();
    const [showMoreModal, setShowMoreModal] = useState(false);
    const [responseIdSelected, setResponseIdSelected] = useState<string | undefined>(undefined);
    const user = useAppSelector(state => state.espressoJeu.signUpResult);
    const urlType = useUrlType();
    
    useEffect(() => {

        if(urlType == "jeu" && !user)
        {
            navigate(ROUTES.JEU_INSCRIPTION_ROUTE);
        }
    }, [])

    const questionsTotalNumber:number[] = [];

    for (let index = 1; index <= totalQuestion; index++) {
        questionsTotalNumber.push(index) 
    }

    return (<>
        <div id="question-page" style={{ position: 'relative', width: '100%', height:'100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

            <div id="panel-header">
                <Header />
                <h2 id="title" className='mb-2 text-center text-white'><b>{data.question}</b></h2>
            </div>

            <Container>
                <Row className='questions justify-content-md-center g-0'>
                    {data.responses.map((response) => {

                        return <Col key={response.id} 
                                    xs="12"
                                    sm="12"
                                    md="3"
                                    lg="3"
                                    className={'text-center item ' + (responseIdSelected == response.id ? "selected": "")} 
                                    onClick={() => setResponseIdSelected(response.id)}>

                            <div className='header-img' style={{backgroundImage:`url(${response.image})`}}></div>
                            <div className='content d-flex justify-content-center align-items-center'>
                                <div>
                                    <h4 id="response-title" className='text-uppercase'><b>{response.title}</b></h4>
                                    <div id="response-subtitle">{response.subTible}</div>
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>

                <div id="btn-validate" className='d-flex justify-content-center mt-4'>
                    {currentIndexQuestion > 1 && <Button sm className='back' onClick={() => navigate(`/${urlType}/question-${currentIndexQuestion-1}`)}><FontAwesomeIcon icon={["fas", "arrow-left"]} size="lg"/></Button>}
                    <Button onClick={() => {if(responseIdSelected)onSelectResponse(responseIdSelected)}}
                            disabled={!responseIdSelected} 
                            className="fs-5">
                        {responseIdSelected && <><FontAwesomeIcon icon={["fas", "check"]} size="lg"/> {'Je valide'}</>}
                        {!responseIdSelected && 'Sélectionnez une réponse'}
                    </Button>
                </div>

                <div id="progress" className='d-flex justify-content-center align-items-center mt-4'>
                    {questionsTotalNumber.map((i) => {
                        const elem = <Fragment key={i}>
                            <div key={i} className={'step ' + (i == currentIndexQuestion ? "selected":"")}><b>{i}</b></div>
                            {i < totalQuestion && <div key={i + "a"} className='separator'></div>}
                        </Fragment>;
                        return elem;
                    })}
                </div>

                <Row className='questions justify-content-md-center g-0 mb-5'>
                    <Col id='show-more' className='mt-4 mb-4 ps-3 pe-3' xs="6">
                        <Row className='align-items-center'>
                            <Col className='text text-center fs-18px font-regular'>{popinTitle}</Col>
                            <Col md="auto" className='btn'>
                                <Button onClick={() => setShowMoreModal(true)} >{"En savoir plus"} <FontAwesomeIcon icon={["fas", "arrow-right"]} size="xs"/></Button>
                            </Col>
                        </Row>

                        <PopIn show={showMoreModal} size='xl' fullscreen onHide={() => setShowMoreModal(false)}> 
                            {popinContent}
                        </PopIn>
                    </Col>
                </Row>
            </Container>
           
        </div>
    </>
    )
}

export default Question